import ReactQuill, { contextType } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";
import "./PostBox.scss"


interface Props {
  text: string;
  onChange: any;
}

export default function RichTextEditor({ text, onChange }: Props) {
  return (
  <div className="PostBox__box overflow-hidden p-0">
      <ReactQuill
        theme="snow"
        value={text}
        onChange={(content, delta, source, editor: any) => {
          onChange(content);
        }}
        preserveWhitespace
        className="RichTextEditor"
      />
      </div>

  );
}
