const STATES = [
  {
    label: "Alaska",
    value: "AK",
    isChecked: false,
  },
  {
    label: "Alabama",
    value: "AL",
    isChecked: false,
  },
  {
    label: "Arkansas",
    value: "AR",
    isChecked: false,
  },
  {
    label: "American Samoa",
    value: "AS",
    isChecked: false,
  },
  {
    label: "Arizona",
    value: "AZ",
    isChecked: false,
  },
  {
    label: "California",
    value: "CA",
    isChecked: false,
  },
  {
    label: "Colorado",
    value: "CO",
    isChecked: false,
  },
  {
    label: "Connecticut",
    value: "CT",
    isChecked: false,
  },
  {
    label: "District of Columbia",
    value: "DC",
    isChecked: false,
  },
  {
    label: "Delaware",
    value: "DE",
    isChecked: false,
  },
  {
    label: "Florida",
    value: "FL",
    isChecked: false,
  },
  {
    label: "Georgia",
    value: "GA",
    isChecked: false,
  },
  {
    label: "Guam",
    value: "GU",
    isChecked: false,
  },
  {
    label: "Hawaii",
    value: "HI",
    isChecked: false,
  },
  {
    label: "Iowa",
    value: "IA",
    isChecked: false,
  },
  {
    label: "Idaho",
    value: "ID",
    isChecked: false,
  },
  {
    label: "Illinois",
    value: "IL",
    isChecked: false,
  },
  {
    label: "Indiana",
    value: "IN",
    isChecked: false,
  },
  {
    label: "Kansas",
    value: "KS",
    isChecked: false,
  },
  {
    label: "Kentucky",
    value: "KY",
    isChecked: false,
  },
  {
    label: "Louisiana",
    value: "LA",
    isChecked: false,
  },
  {
    label: "Massachusetts",
    value: "MA",
    isChecked: false,
  },
  {
    label: "Maryland",
    value: "MD",
    isChecked: false,
  },
  {
    label: "Maine",
    value: "ME",
    isChecked: false,
  },
  {
    label: "Minnesota",
    value: "MN",
    isChecked: false,
  },
  {
    label: "Missouri",
    value: "MO",
    isChecked: false,
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
    isChecked: false,
  },
  {
    label: "Mississippi",
    value: "MS",
    isChecked: false,
  },
  {
    label: "Montana",
    value: "MT",
    isChecked: false,
  },
  {
    label: "National",
    value: "NA",
    isChecked: false,
  },
  {
    label: "North Carolina",
    value: "NC",
    isChecked: false,
  },
  {
    label: "North Dakota",
    value: "ND",
    isChecked: false,
  },
  {
    label: "Nebraska",
    value: "NE",
    isChecked: false,
  },
  {
    label: "New Hampshire",
    value: "NH",
    isChecked: false,
  },
  {
    label: "New Jersey",
    value: "NJ",
    isChecked: false,
  },
  {
    label: "New Mexico",
    value: "NM",
    isChecked: false,
  },
  {
    label: "Nevada",
    value: "NV",
    isChecked: false,
  },
  {
    label: "New York",
    value: "NY",
    isChecked: false,
  },
  {
    label: "Ohio",
    value: "OH",
    isChecked: false,
  },
  {
    label: "Oklahoma",
    value: "OK",
    isChecked: false,
  },
  {
    label: "Oregon",
    value: "OR",
    isChecked: false,
  },
  {
    label: "Pennsylvania",
    value: "PA",
    isChecked: false,
  },
  {
    label: "Puerto Rico",
    value: "PR",
    isChecked: false,
  },
  {
    label: "Rhode Island",
    value: "RI",
    isChecked: false,
  },
  {
    label: "South Carolina",
    value: "SC",
    isChecked: false,
  },
  {
    label: "South Dakota",
    value: "SD",
    isChecked: false,
  },
  {
    label: "Tennessee",
    value: "TN",
    isChecked: false,
  },
  {
    label: "Texas",
    value: "TX",
    isChecked: false,
  },
  {
    label: "Utah",
    value: "UT",
    isChecked: false,
  },
  {
    label: "Virginia",
    value: "VA",
    isChecked: false,
  },
  {
    label: "Virgin Islands",
    value: "VI",
    isChecked: false,
  },
  {
    label: "Vermont",
    value: "VT",
    isChecked: false,
  },
  {
    label: "Washington",
    value: "WA",
    isChecked: false,
  },
  {
    label: "Wisconsin",
    value: "WI",
    isChecked: false,
  },
  {
    label: "West Virginia",
    value: "WV",
    isChecked: false,
  },
  {
    label: "Wyoming",
    value: "WY",
    isChecked: false,
  },
];

const AREATYPES = [
  {
    label: "State-Based",
    value: "state",
  },
  {
    label: "City-Based",
    value: "cities",
  },
];
const LANGUAGES = [
  {
    label: "English",
    value: "english",
  },
];

const DELIVERY_METHODS = [
  {
    label: "Email Only",
    value: "email",
  },
  {
    label: "RSS",
    value: "rss",
  },
  {
    label: "API",
    value: "api",
  },
];

const SPORTS = [
  {
    label: "Football",
    value: "football",
    isChecked: false,
  },
  {
    label: "Lacrosse",
    value: "lacrosse",
    isChecked: false,
  },
  {
    label: "Soccer",
    value: "soccer",
    isChecked: false,
  },
  {
    label: "Baseball",
    value: "baseball",
    isChecked: false,
  },
  {
    label: "Softball",
    value: "softball",
    isChecked: false,
  },
  {
    label: "Basketball",
    value: "basketball",
    isChecked: false,
  },
  {
    label: "Hockey",
    value: "hockey",
    isChecked: false,
  },
];
const SEOMETAS = [
  {
    label: "Home Team Name",
    value: "$home_team_name",
    isChecked: false,
  },
  {
    label: "Away Team Name",
    value: "$away_team_name",
    isChecked: false,
  },
  {
    label: "State",
    value: "$state",
    isChecked: false,
  },
  {
    label: "Sport Name",
    value: "$sport_name",
    isChecked: false,
  },
];

const ROUNDUPMETAS = [
  {
    label: "City List",
    value: "$city_list",
    isChecked: false,
  },
  {
    label: "Teams List",
    value: "$teams_list",
    isChecked: false,
  },
  {
    label: "Sport Name",
    value: "$sport_name",
    isChecked: false,
  },
];

const CMS = [
  {
    label: "Town New CMS",
    value: "blox",
  },
  {
    label: "WordPress",
    value: "wordpress",
  },
  {
    label: "BrightSpot",
    value: "brightspot",
  },
  {
    label: "CIAT",
    value: "ciat",
  },
];

const GENDER_LEVEL = [
    {
      label: " Boys High School Varsity",
      value: "1010",
      isChecked: false,
    },
    {
      label: " Girls High School Varsity",
      value: "1040",
      isChecked: false
    }
];

const TIMEZONE = [
    {
        label: "US/Eastern",
        value: "US/Eastern"
    },
    {
        label: "US/Central",
        value: "US/Central"
    },
    {
        label: "US/Pacific",
        value: "US/Pacific"
    },
    {
      label: "US/Mountain",
      value: "US/Mountain"
    },
    {
        label: "US/Hawaii",
        value: "US/Hawaii"
    },    
    {
        label: "US/Alaska",
        value: "US/Alaska"
    },     
];
export { STATES, AREATYPES, LANGUAGES, DELIVERY_METHODS, SPORTS, CMS, GENDER_LEVEL, TIMEZONE, SEOMETAS , ROUNDUPMETAS};
