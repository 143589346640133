import './ForgotPassword.scss';
import { FormikErrors, useFormik } from 'formik';
import { Input } from "../../components";
import LoginContainer from '../../containers/LoginContainer';
import { isEmail } from '../../core/helpers';
interface FormikValues {
  email: string;
}

export default function ForgotPassword() {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {};
      if (!values.email) {
        errors.email = 'Required';
      }
      if (values.email && !isEmail(values.email)) {
        errors.email = 'Email not valid';
      }
      return errors;
    },
    onSubmit: (values) => {},
  });

  return (
    <LoginContainer>
      <div className='ForgotPassword__card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Forgot Password?</div>
        <div className='mb--50'>Enter the email associated with Lede AI Admins below.</div>
        <form className='w-100 Login__form ' onSubmit={formik.handleSubmit}>
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            name='email'
            label='Enter your email'
            required={true}
            className='mb-5'
            error={formik.touched.email && formik.errors.email}
          />
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <button className='btn w-100 Login__btn mb-3' type='submit'>
              Send Email
            </button>
            <div>
              Didn’t get the email?&nbsp;
              {/* eslint-disable-next-line */}
              <a href='#' className='fw-bold'>
                Contact an admin.
              </a>
            </div>
          </div>
        </form>
      </div>
    </LoginContainer>
  );
}
