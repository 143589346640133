import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Input from '../../components/Input/Input';
import PasswordInput from "../../components/Input/Password";
import Loader from '../../components/Loader/Loader';
import LoginContainer from '../../containers/LoginContainer';
import { register } from '../../core/api';
import { setEmailPasswordToLocalStorage } from '../../core/auth';
import { RoutesEnum } from '../../core/enums';
import {
  containsCapital,
  containsDigit,
  containsNonCapital,
  containsSpecialCharacter,
  isEmail,
  isPasswordLongEnough,
  isPasswordValid,
  noSpacesOnEnds,
} from '../../core/helpers';

import { useAppDispatch } from '../../hooks/useReduxToolkit';
import './SignUp.scss';

export interface RegisterInput {
  name: string;
  family_name: string;
  email: string;
  company_name: string;
  password: string;
  confirm_password: string;
}

export default function SignUp() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [pwdState, setPwdState] = useState("password")

  const formik = useFormik<RegisterInput>({
    initialValues: {
      name: '',
      family_name: '',
      email: '',
      company_name: '',
      password: '',
      confirm_password: '',
    },
    validate: (values) => {
      const errors: FormikErrors<RegisterInput> = {};

      if (!values.name) {
        errors.name = 'Required';
      }
      if (!values.family_name) {
        errors.family_name = 'Required';
      }
      if (!values.company_name) {
        errors.company_name = 'Required';
      }
      if (!values.email) {
        errors.email = 'Required';
      }

      if (values.email && !isEmail(values.email)) {
        errors.email = 'Email not valid';
      }

      if (!values.password) {
        errors.password = 'Required';
      }

      if (values.password && !isPasswordValid(values.password)) {
        const err = getPasswordError(values.password);
        if (err === 'no whitespace from both ends') {
          errors.password = err;
        } else {
          errors.password = 'should contain ' + err;
        }
      }

      if (!values.confirm_password) {
        errors.confirm_password = 'Required';
      }

      if (
        values.confirm_password &&
        !isPasswordValid(values.confirm_password)
      ) {
        const err = getPasswordError(values.confirm_password);
        if (err === 'no whitespace from both ends') {
          errors.confirm_password = err;
        } else {
          errors.confirm_password = 'should contain ' + err;
        }
      }

      if (
        values.password &&
        values.confirm_password &&
        isPasswordValid(values.confirm_password) &&
        isPasswordValid(values.password) &&
        values.password !== values.confirm_password
      ) {
        errors.confirm_password =
          'Password and confirm password shloud be equal';
      }

      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      register(values)
        .then((res) => {
          setEmailPasswordToLocalStorage(values!.email, values!.password);
          history.push(RoutesEnum.ConfirmAccount);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            // This checks if the error is coming from the server response
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 4500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
            });
            console.error(err.response.data); // Log the entire error response for debugging
          } else {
            // Handle other types of errors (e.g., network errors)
            toast.error("An error occurred. Please try again later.", {
              position: "top-right",
              autoClose: 4500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
            });
            console.error(err); // Log the entire error object for debugging
          }
          setIsLoading(false);
        });
    },
  });

  return (
    <LoginContainer>
      {isLoading && <Loader />}
      <div className='SignUp__card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Sign Up</div>
        <div className='mb--80'>Welcome! Let's get started.</div>
        <form className='w-100 Login__form ' onSubmit={formik.handleSubmit}>
          <Input
            onBlur={formik.handleBlur}
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            name='name'
            label='First Name'
            className='mb-4'
            error={formik.touched.name && formik.errors.name}
          />
          <Input
            onBlur={formik.handleBlur}
            required
            value={formik.values.family_name}
            onChange={formik.handleChange}
            name='family_name'
            label='Last Name'
            className='mb-4'
            error={formik.touched.family_name && formik.errors.family_name}
          />
          <Input
            onBlur={formik.handleBlur}
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            name='email'
            label='Email'
            className='mb-4'
            error={formik.touched.email && formik.errors.email}
          />
          <Input
            onBlur={formik.handleBlur}
            required
            value={formik.values.company_name}
            onChange={formik.handleChange}
            name='company_name'
            label='Company Name'
            className='mb-4'
            error={formik.touched.company_name && formik.errors.company_name}
          />
          <PasswordInput
            onBlur={formik.handleBlur}
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            externalPwd={{
              value: pwdState,
              setValue: setPwdState,
            }}
            name='password'
            label='Password'
            className='mb-4'
            type='password'
            error={formik.touched.password && formik.errors.password}
          />
          <Input
            onBlur={formik.handleBlur}
            required
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            name='confirm_password'
            label='Confirm Password'
            className='mb-4'
            type={ pwdState }
            error={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
          />
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <button className='btn' type='submit'>
              Create Account
            </button>
          </div>
        </form>
      </div>
    </LoginContainer>
  );
}

export const PASSWORD_VALIDATION_PARAMETERS = [
  'min 8 characters',
  'number',
  'special character',
  'uppercase letter',
  'lowercase letter',
  'no whitespace from both ends',
];

export const passwordValidators: {
  [key: string]: (v: string) => boolean;
} = {
  'min 8 characters': isPasswordLongEnough,
  number: containsDigit,
  'special character': containsSpecialCharacter,
  'uppercase letter': containsCapital,
  'lowercase letter': containsNonCapital,
  'no whitespace from both ends': noSpacesOnEnds,
};

const getPasswordError = (value: string) => {
  return PASSWORD_VALIDATION_PARAMETERS.reduce((acc: string[], item) => {
    if (passwordValidators[item] && !passwordValidators[item](value)) {
      acc.push(item);
    }
    return acc;
  }, [])[0];
};
