import React from 'react'
import './PublicationFrame.scss'
import imgg from '../../icons/imagePlaceholder.svg'

type props={
  FrameImg:string,
  handleClick:()=>void,
  publicationName:string

}

const PublicationFrame = ({FrameImg,handleClick,publicationName}:props) => {


  return (
    <div className="pf-frame"   onClick={()=>{ handleClick()}}>
      <div className="imageContainer"  >
      <img src={imgg}   alt="" />
      </div>
      <div className="description-box">
        <p className="description"> 
          {publicationName}
        </p>
      
      </div>
    </div>
  )
}

export default PublicationFrame