import "./Footer.scss";
import imgg from '../../imgs/logo.png'
import { Link } from "react-router-dom";
import { RoutesEnum } from '../../core/enums'

export default function Footer() {
  return (
    <div className="Footer">
      <div className="leftContainer">
        <img className="logoImg"  src={imgg} alt="" />
      </div>
      <div className="rightContainer">
        <div className="mb-2">Support Documentation</div>
        <div className="mb-2">Report an Issue</div>
        <div className="mb-2">content@ledeai.com</div>
        <div>
          <Link className='text-white' to={RoutesEnum.TermsConditions}>Terms of Service & Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}
