import { useSelector } from 'react-redux';
import { RootState } from '../../core/store'
import "./Header.scss"
import imgg from '../../imgs/no-user-avatar.png'
import { useState, useMemo } from 'react';
import { MouseEvent } from 'react';
import { logOutUser } from '../../core/auth';



const Header = () => {
  const userData = useSelector((state : RootState) => state.user.user);
  const [isDropdownOpend , setDropdownOpen ] = useState(false);

  const handleLogout = (e: any): void => {
    const res = logOutUser();
    e.stopPropagation()
    try {
    } catch (error) { }
    window.location.reload()
  }
  
  const DropDownlist = <div className='dropdown-content' onClick={ (e: MouseEvent<HTMLDivElement>) => handleLogout(e) }>Logout</div>

  return (
    <div className='main-header'>
      <div className="mh-rightContent">
        <div className="mh-userName">
              Hello, {userData?.first_name}
        </div>
        <div className="mh-profile">
          <div className="dropdown ">
          <img  className="profile" src={imgg} alt="" onClick={ (e: MouseEvent<HTMLDivElement>) =>
                              setDropdownOpen(!isDropdownOpend)}/>
        <>
          {isDropdownOpend  ? DropDownlist : false}
        </>
        </div>
        </div>
      </div>
    </div>
  )

}

export default Header