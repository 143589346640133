import Sidebar from "../components/Sidebar/Sidebar";
import { IRoute } from "../core/types";
import { RoutesEnum } from "../core/enums";
import Page404 from "../pages/Page404";

import Routes from "../Routes";
import "./DashboardContainer.scss";
import AdminDashboard from "../pages/AdminDashboad/FirstPage";
import Header from "../components/header/Header";
import Configuration from "../pages/Configuration/Configuration";
import PublishingHistory from "../pages/PublishingHistory/PublishingHistory1";
import PublicationSettings from "../pages/PublicationSettings/PublicationSettings";
import TermsConditions from "../pages/Other/TermsConditions";


const routes: IRoute[] = [
  {
    path: "/",
    component: AdminDashboard,
    exact: true,
  },
  {
    path: '/configuration',
    component: Configuration
  },
  {
    path: '/publicationSetting',
    component: PublicationSettings
  },
  {
    path: "/history",
    component: PublishingHistory
  },
  {
    path: RoutesEnum.TermsConditions,
    component: TermsConditions
  },
  {
    path: "*",
    redirectTo: "/404",
    component: Page404,
  },
];

export default function DashboardContainer() {
  return (
    <div className=" DashboardContainer d-flex"  >

      <Sidebar />
      <div className="flex-grow-1" style={{ minWidth: 0, overflow: "hidden" }} >
        <Header />
        {/* <AdminDashboard/> */}
        <Routes routes={routes} />
      </div>


    </div>
  );
}
