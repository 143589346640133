/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEventHandler, ChangeEvent, MouseEventHandler, MouseEvent } from "react";
import { SelectInput, Input } from "../../components";
import { DELIVERY_METHODS, CMS, SEOMETAS } from "../../constants/constants";
import { useState } from "react";
import { ReactComponent as Alert } from "./../../icons/Alert.svg";
import { CHECKEDOPTION } from "../../core/types";

import "./ConfigTab.scss";
import Spinner from "../../components/Spinner/Spinner";

interface Props {
  editMode: boolean;
  contentMethodValue: string;
  contentEmailContacts?: string;
  cmsValue: string;
  publish_status?: boolean;
  deep_link?: boolean;
  apiKeyValue: string;
  apiSecretValue: string;
  authorNameValue: string;
  additionalTags: string;
  urlSlugValue: string;
  apiGetUrlValue: string;
  apiPostUrlValue: string;
  brightSpotType: string;
  brightSpotFormat: string;
  ciatBY: string;
  ciatBylineSource: string;
  ciatSiteCode: string;
  ciatAssociationIds: string;
  ciatPaywallType: string;
  rssFeedUrl?: string;

  onChangeBrightSpotType: (value: string) => void;
  onChangeBrightSpotFormat: (value: string) => void;

  onChangeCiatSiteCode: (value: string) => void;
  onChangeCiatBY: (value: string) => void;
  onChangeCiatBylineSource: (value: string) => void;
  onChangeCiatAssociationIds: (value: string) => void;
  onChangeCiatPaywallType: (value: string) => void;

  onContentMethodChange: (value: string) => void;
  onCmsChange: (value: string) => void;
  onChangeApikey: ChangeEventHandler<HTMLInputElement>;
  onChangeApiSecret: ChangeEventHandler<HTMLInputElement>;
  onChangeAuthorName: ChangeEventHandler<HTMLInputElement>;
  onChangeAdditionalTag: ChangeEventHandler<HTMLInputElement>;
  onChangeUrlSlug: (value: string) => void;
  onChangeApiGetUrl: ChangeEventHandler<HTMLInputElement>;
  onChangeApiPostUrl: ChangeEventHandler<HTMLInputElement>;
  onChangeEmailContacts: ChangeEventHandler<HTMLInputElement>;
  onChangePublishStatus: (value: boolean) => void;
  onChangeDeepLink: (value: boolean) => void;
  checkedSeoMetaList: CHECKEDOPTION[],
  onSubmitSave: any;
  onClicktestPublish: MouseEventHandler<HTMLButtonElement>;
  testPubColor: string;
  testPubButtonStatus: boolean;
}

const allFiltersPreSeo = [
  {
    subtitle: "Sports",
    value: [],
    options: SEOMETAS,
  },
];

const ContentDeliveryTab = (props: Props) => {
  
  return (
    <div className="form-container ">
      <form className="w-100 px-4 d-flex flex-column configuration-form ">
        <span className="fw-bold mb-4 section-title">
          Content Delivery Settings
        </span>
        <div className="input-container">
          <span className="mb-2 d-block">
            By default, Lede AI roundups are published by gender and sport and
            sorted alphabetically by winning team. They contain the entirety of
            that day’s individual articles, aggregated. For more documentation
            about Lede AI roundups, click here.
          </span>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">Content delivery method</span>
          <span className="mb-2 d-block">
            We’ll email you a copy of your article exports no matter what.
          </span>
          <SelectInput.SelectDropdown
            initlabel="Email"
            options={DELIVERY_METHODS}
            title={""}
            required={true}
            disabled={!props.editMode}
            value={props.contentMethodValue}
            onChange={props.onContentMethodChange}
          />
        </div>
        {/* {(!props.contentMethodValue ||
          props.contentMethodValue === "email") && ( */}
          <div className="input-container">
            <span className="fw-bold mb-2 d-block">
              Contacts to receive article exports
            </span>
            <Input
              value={props.contentEmailContacts ? props.contentEmailContacts : ''}
              onChange={props.onChangeEmailContacts}
              name="contacts"
              className="light clientModalInput"
              required={true}
              readOnly={!props.editMode}
              placeholder={
                "please seperate multiple email adddresses with comma (,)"
              }
            />
          </div>
        {/* )} */}
        {props.contentMethodValue === "rss" && (
          <p>
            RSS feed URL:{" "}
            <a href="#" style={{ color: "#5EB5CA" }}>
              {props.rssFeedUrl}
            </a>
          </p>
        )}
        {props.contentMethodValue === "api" && (
          <>
            <span className="fw-bold mb-4 section-title">
              Configure Auto-Publish
            </span>
            <span className="mb-2 d-block">
              If you have questions, consult our auto-publish documentation
              here.
            </span>

          <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Publishing Status
          </span>
          <span className="mb-2 d-block">
            Select "Publish" if you only want us to publish the articles in "Publish" Status.
            <br />
          </span>

            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-pubstatus-yes"
                  name="publishstatus"
                  checked={props.publish_status}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangePublishStatus(e.target.checked)
                  }
                />
                <label htmlFor="dl-pubstatus-yes">Publish</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-pubstatus-no"
                  name="publishstatus"
                  checked={!props.publish_status}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangePublishStatus(! e.target.checked)
                  }
                />
                <label htmlFor="dl-pubstatus-no">Draft</label>
              </div>
            </div>
          </div>

          <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Deep Linking
          </span>
          <span className="mb-2 d-block">
            Include Deep Linking to other,relevant articles inside the individual assets.
            <br />
          </span>

            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-deeplink-yes"
                  name="deeplink"
                  checked={props.deep_link}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeDeepLink(e.target.checked)
                  }
                />
                <label htmlFor="dl-pubstatus-yes">Include Deep Linking</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-deeplink-no"
                  name="deeplink"
                  checked={!props.deep_link}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeDeepLink(! e.target.checked)
                  }
                />
                <label htmlFor="dl-pubstatus-no">Do Not Deep Link</label>
              </div>
            </div>
          </div>
            <div className="input-container mt-4">
              <span className="fw-bold mb-2 d-block">CMS Selection </span>
              <SelectInput.SelectDropdown
                initlabel="Town New CMS"
                options={CMS}
                value={props.cmsValue}
                title={""}
                required={true}
                disabled={!props.editMode}
                onChange={props.onCmsChange}
              />
            </div>
            { (!props.cmsValue || props.cmsValue === "blox") && (
            <>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">API key</span>
              <Input
                value={props.apiKeyValue}
                onChange={props.onChangeApikey}
                name="apikey"
                className="light clientModalInput"
                required={true}
                readOnly={!props.editMode}

              />
            </div>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">API Secret</span>
              <Input
                value={props.apiSecretValue}
                onChange={props.onChangeApiSecret}
                name="api-secret"
                className="light clientModalInput"
                required={true}
                type={"password"}
                readOnly={!props.editMode}

              />
            </div>
            </>
            )}
            { props.cmsValue === "wordpress" && (
            <>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">User Name</span>
              <Input
                value={props.apiKeyValue}
                onChange={props.onChangeApikey}
                name="apikey"
                className="light clientModalInput"
                required={true}
                readOnly={!props.editMode}

              />
            </div>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">Password</span>
              <Input
                value={props.apiSecretValue}
                onChange={props.onChangeApiSecret}
                name="api-secret"
                className="light clientModalInput"
                required={true}
                type={"password"}
                readOnly={!props.editMode}

              />
            </div>
            </>
            )}
            { props.cmsValue === "brightspot" && (
            <>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">API Key</span>
              <Input
                value={props.apiKeyValue}
                onChange={props.onChangeApikey}
                name="apikey"
                className="light clientModalInput"
                required={true}
                type={"password"}
                readOnly={!props.editMode}

              />
            </div>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">Session ID</span>
              <Input
                value={props.apiSecretValue}
                onChange={props.onChangeApiSecret}
                name="api-secret"
                className="light clientModalInput"
                required={true}
                readOnly={!props.editMode}

              />
            </div>
            </>
            )}
            { props.cmsValue === "ciat" && (
            <>
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">API Key</span>
              <Input
                value={props.apiKeyValue}
                onChange={props.onChangeApikey}
                name="apikey"
                className="light clientModalInput"
                required={true}
                type={"password"}
                readOnly={!props.editMode}
              />
            </div>
            </>
            )}
            <div className="input-container">
              <span className="fw-bold mb-2 d-block">Author name</span>
              <Input
                value={props.authorNameValue}
                onChange={props.onChangeAuthorName}
                name="author-name"
                className="light clientModalInput"
                required={true}
                readOnly={!props.editMode}
              />
            </div>
        <div className="input-container">

            <SelectInput.MultiSelectDropdown
            value={allFiltersPreSeo[0].value}
            required={true}
            title="SEO metadata"
            checkedList={props.checkedSeoMetaList}
            options={allFiltersPreSeo[0].options}
            onChange={() => null}
            preCheckedAll={true}
            disabled={!props.editMode}
          />
          
        </div>

        <div className="input-container">
            <span className="fw-bold mb-2 d-block">Additional SEO Tags</span>
            <span className="mb-2 d-block">
            Additional SEO tags that will help for discoverability or tracking purposes, such as “ai” or “ledeai.”
            </span>
            {props.editMode ? (
              <>
              <Input
              value={props.additionalTags}
              onChange={props.onChangeAdditionalTag}
              name="additional-meta"
              className="light clientModalInput"
              required={false}
              readOnly={!props.editMode}
            />
            </>
             ) : 
             <span className="mb-2 d-block">{props.additionalTags}</span>
             }
            </div>
          <div className="input-container">
            <span className="fw-bold mb-2 d-block">Article URL slug</span>
            <span className="mb-2 d-block">
              Where you want the article to be sorted on your website. <br />
              Click either of the buttons below to add dynamic sorting, such
              as “https://yoursite.com/sports/gender-sport/”
            </span>
              <Input
              value={props.urlSlugValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.onChangeUrlSlug(e.target.value)
              }
              name="url-slug"
              className="light clientModalInput"
              required={true}
              readOnly={!props.editMode}
            />
            {props.editMode && (
            <div className="gender-sport-wrapper">
            <button
                className="gender-sport-button"
                type="button"
                disabled={!props.editMode}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  props.onChangeUrlSlug(props.urlSlugValue + "$gender")
                }
              >
                Gender
              </button>
              <button
                className="gender-sport-button"
                type="button"
                disabled={!props.editMode}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  props.onChangeUrlSlug(props.urlSlugValue + "$sportname")
                }
              >
                Sport
              </button>
              </div>
            )}

            </div>

            {( props.cmsValue === "blox") && (
              <>
                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">API GET-URL</span>
                  <Input
                    value={props.apiGetUrlValue}
                    onChange={props.onChangeApiGetUrl}
                    name="api-get-url"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">API POST-URL</span>
                  <Input
                    value={props.apiPostUrlValue}
                    onChange={props.onChangeApiPostUrl}
                    name="api-post-url"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>
              </>
            )}
            {( props.cmsValue === "wordpress") && (
              <>
                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">Wordpress Login URL</span>
                  <Input
                    value={props.apiGetUrlValue}
                    onChange={props.onChangeApiGetUrl}
                    name="api-get-url"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">Wordpress Post URL</span>
                  <Input
                    value={props.apiPostUrlValue}
                    onChange={props.onChangeApiPostUrl}
                    name="api-post-url"
                    className="light clientModalInput"
                    required={true}

                  />
                </div>
              </>
            )}
            {( props.cmsValue === "brightspot") && (
              <>
                <div className="input-container">

                  <span className="fw-bold mb-2 d-block">Root Domain URL</span>
                  <span className="mb-2 d-block">Optional Field. Please provide this if you would like us to use deep linking of articles
                  by combining the permalink section and this URL </span>
                  <Input
                    value={props.apiGetUrlValue}
                    onChange={props.onChangeApiGetUrl}
                    name="api-get-url"
                    className="light clientModalInput"
                    required={false}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">BrightSpot Post URL</span>
                  <Input
                    value={props.apiPostUrlValue}
                    onChange={props.onChangeApiPostUrl}
                    name="api-post-url"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">BrightSpot Format</span>
                  <Input
                    value={props.brightSpotFormat}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeBrightSpotFormat(e.target.value)
                    }
                    name="brightspot-format"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">BrightSpot Type</span>
                  <Input
                    value={props.brightSpotType}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeBrightSpotType(e.target.value)
                    }
                    name="brightspot-type"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>
              
              </>
            )}
            {( props.cmsValue === "ciat") && (
              <>
                <div className="input-container">

                  <span className="fw-bold mb-2 d-block">CIAT Get URL</span>
                  <Input
                    value={props.apiGetUrlValue}
                    onChange={props.onChangeApiGetUrl}
                    name="api-get-url"
                    className="light clientModalInput"
                    required={false}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">CIAT Post URL</span>
                  <Input
                    value={props.apiPostUrlValue}
                    onChange={props.onChangeApiPostUrl}
                    name="api-post-url"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">CIAT By</span>
                  <Input
                    value={props.ciatBY}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeCiatBY(e.target.value)
                    }
                    name="ciat-by"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">CIAT Byline Source</span>
                  <Input
                    value={props.ciatBylineSource}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeCiatBylineSource(e.target.value)
                    }
                    name="ciat-bylinesource"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">CIAT Site Code</span>
                  <Input
                    value={props.ciatSiteCode}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeCiatSiteCode(e.target.value)
                    }
                    name="ciat-site-code"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>
                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">CIAT Association ID's</span>
                  <Input
                    value={props.ciatAssociationIds}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeCiatAssociationIds(e.target.value)
                    }
                    name="ciat-association-ids"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

                <div className="input-container">
                  <span className="fw-bold mb-2 d-block">CIAT Paywall Type</span>
                  <Input
                    value={props.ciatPaywallType}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.onChangeCiatPaywallType(e.target.value)
                    }
                    name="ciat-paywalltype"
                    className="light clientModalInput"
                    required={true}
                    readOnly={!props.editMode}

                  />
                </div>

              </>
            )}
            <button
              type="button"
              className="btn btn-primary align-self-start mb-4"
              style={{ height: "50px", width: "175px" , backgroundColor: props.testPubColor}}
              onClick={props.onClicktestPublish}
              disabled={ !props.testPubButtonStatus || !props.editMode }
            >
              Test Connection
            </button>
            {!props.testPubButtonStatus &&
            <>
              <Spinner />
            </>
            }
          </>
        )}
        <button
          className="btn btn-primary align-self-start"
          style={{ height: "55px", width: "118px" }}
          type="button"
          onClick={props.onSubmitSave}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default ContentDeliveryTab;
