/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const ContentIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`content-icon ${className}`}
      fill="none"
      height="190"
      viewBox="0 0 190 190"
      width="190"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M88.4276 82.0717C86.0689 71.7471 88.1176 61.4629 93.3338 53.1466C85.0175 47.9034 74.6929 45.8412 64.3413 48.1999C54.8389 50.37 46.9808 55.8962 41.7107 63.2286C51.0244 68.31 58.2085 77.2194 60.756 88.3797C63.3035 99.54 60.6886 110.673 54.5019 119.3C62.4273 123.626 71.9028 125.203 81.4053 123.033C91.7569 120.675 100.154 114.34 105.384 106.01C97.0809 100.78 90.7729 92.3964 88.4276 82.0717Z"
        fill="white"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M60.7695 88.3797C58.2221 77.2194 51.038 68.31 41.7242 63.2286C35.5375 71.8549 32.9227 82.9883 35.4701 94.1486C38.0176 105.309 45.2017 114.218 54.5155 119.3C60.7021 110.673 63.317 99.54 60.7695 88.3797Z"
        fill="white"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M88.4278 82.0717C90.7865 92.3964 97.0945 100.78 105.384 106.01C110.6 97.6935 112.635 87.4093 110.29 77.0847C107.931 66.76 101.623 58.3763 93.334 53.1466C88.1178 61.4629 86.0825 71.7471 88.4278 82.0717Z"
        fill="white"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M64.3413 48.1999L81.3918 122.966"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M110.25 77.0577L35.4836 94.1081"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M127.22 136.666C150.001 124.333 161.766 101.955 153.498 86.6821C145.23 71.4096 120.059 69.0268 97.2783 81.3599C74.4973 93.693 62.7322 116.072 71.0003 131.344C79.2685 146.617 104.439 149 127.22 136.666Z"
        fill="white"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M135.05 75.4267C135.05 75.4267 132.327 101.912 153.772 107.465"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M93.2528 142.86C93.2528 142.86 93.6167 116.24 71.7678 112.601"
        stroke="#415574"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M101.798 109.838L107.81 120.418"
        stroke="#415574"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M110.465 104.904L116.477 115.499"
        stroke="#415574"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M119.132 99.9848L125.144 110.566"
        stroke="#415574"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
      <path
        className="path"
        d="M129.564 101.063L98.3074 118.814"
        stroke="#415574"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="6.73932"
      />
    </svg>
  );
};
