import { ChangeEventHandler, ChangeEvent,  MouseEvent } from "react";
import { SelectInput, Input } from "../../components";
import { ReactComponent as Alert } from "./../../icons/Alert.svg";
import { ROUNDUPMETAS } from "../../constants/constants";
import { CHECKEDOPTION } from "../../core/types";
import "./ConfigTab.scss";

interface Props {
  editMode: boolean;
  stateAthleticValue?: string;
  onChangeStateAthletic: ChangeEventHandler<HTMLInputElement>;
  autoPublishRoundUp?: boolean;
  onChangeAutoPublishRoundup : (value: boolean) => void;
  publishRoundUpOnly?: boolean;
  onChangePublishRoundupOnly: (value: boolean) => void;
  apStyleRoundUps?: boolean;
  onChangeapStyleRoundups: (value: boolean) => void;
  checkedRoundupTagList?: CHECKEDOPTION[];
  roundupHeadline: string;
  onChangeRoundupHeadline: (value: string) => void;
  onSubmitSave: any;
}
const RoundupTab = (props: Props) => {
  const allFiltersPreSeo = [
    {
      subtitle: "Sports",
      value: [],
      options: ROUNDUPMETAS,
    },
  ];

  return (
    <div className="form-container ">
      <form className="w-100 px-4 d-flex flex-column configuration-form ">
        <span className="fw-bold mb-4 section-title">Roundup Settings</span>
        <div className="input-container">
          <span className="mb-2 d-block">
            By default, Lede AI roundups are published by gender and sport and
            sorted alphabetically by winning team. They contain the entirety of
            that day’s individual articles, aggregated. For more documentation
            about Lede AI roundups, click here.
          </span>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            State athletic association abbreviation
          </span>
          <span className="mb-2 d-block">
            This field is not required, but we will create an SEO-optimized lede
            using your state athletic association abbreviation if you supply it.
            For example, “OHSAA” for Ohio.
          </span>
            <Input
              value={props.stateAthleticValue ? props.stateAthleticValue : ""}
              onChange={props.onChangeStateAthletic}
              name="clientName"
              className="light clientModalInput"
              required={false}
              readOnly={!props.editMode}
              style={{ fontSize: "22px" }}
            />
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">Publish the roundup only</span>
          <span className="mb-2 d-block">
            Select Yes if you only want us to publish a roundup and you do not
            want us to publish individual articles. . This is not advised.
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input type="radio" 
                       id="roundup-only-yes" 
                       name="roundup-only" 
                       checked={props.publishRoundUpOnly}
                       disabled={!props.editMode}
                       onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                props.onChangePublishRoundupOnly(e.target.checked)}/>
                <label htmlFor="roundup-only-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input type="radio" 
                        id="roundup-only-no" 
                        name="roundup-only" 
                        checked={!props.publishRoundUpOnly}
                        disabled={!props.editMode}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        props.onChangePublishRoundupOnly(!e.target.checked)}/> 
                <label htmlFor="roundup-only-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">Auto-publish the roundup</span>
          <span className="mb-2 d-block">
            Select No if you do not want us to auto publish the roundup. This is
            not advised.{" "}
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input type="radio" 
                       id="auto-publish-yes" 
                       name="auto-publish" 
                       checked={props.autoPublishRoundUp}
                       disabled={!props.editMode}
                       onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                props.onChangeAutoPublishRoundup(e.target.checked)}/>
                <label htmlFor="auto-publish-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input type="radio" 
                       id="auto-publish-no" 
                       name="auto-publish" 
                       checked={!props.autoPublishRoundUp}
                       disabled={!props.editMode}
                       onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                props.onChangeAutoPublishRoundup(!e.target.checked)}/>
                <label htmlFor="auto-publish-no">No</label>
              </div>
            </div>
        </div>

        <div className="input-container">
          <span className="fw-bold mb-2 d-block">AP-style roundups</span>
          <span className="mb-2 d-block">
            Just like the good old days. The AP-style roundup is the winning
            team, losing team, and score. <br />
            <br />
            [Team A] beat [Team B] [Score] [Team C] beat [Team D] [Score] <br />
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="ap-style-roundup-yes"
                  name="ap-style-roundup"
                  checked={props.apStyleRoundUps}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                           props.onChangeapStyleRoundups(e.target.checked)}/>

                <label htmlFor="ap-style-roundup-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="ap-style-roundup-no"
                  name="ap-style-roundup"
                  checked={!props.apStyleRoundUps}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                           props.onChangeapStyleRoundups(!e.target.checked)}/>
                <label htmlFor="ap-style-roundup-no">No</label>
              </div>
            </div>
        </div>

        <div className="input-container">
            <SelectInput.MultiSelectDropdown
            value={allFiltersPreSeo[0].value}
            required={true}
            title="RoundUp SEO MetaData"
            checkedList={props.checkedRoundupTagList}
            options={allFiltersPreSeo[0].options}
            onChange={() => null}
            preCheckedAll={true}
            disabled={!props.editMode}
          />
        </div>

        <div className="input-container">
            <span className="fw-bold mb-2 d-block">RoundUp Headline Format</span>
            <span className="mb-2 d-block">
              How do you want the headlines for the roundup articles to look like? <br />
              Click either of the buttons below to add dynamic sorting, such
              as “Roundup for $gender $sportname - $date”
            </span>
              <Input
              value={props.roundupHeadline}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.onChangeRoundupHeadline(e.target.value)
              }
              name="url-slug"
              className="light clientModalInput"
              required={true}
              readOnly={!props.editMode}
            />
            {props.editMode && (
            <div className="gender-sport-wrapper">
            <button
                className="gender-sport-button"
                type="button"
                disabled={!props.editMode}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  props.onChangeRoundupHeadline(props.roundupHeadline + "$gender")
                }
              >
                Gender
              </button>
              <button
                className="gender-sport-button"
                type="button"
                disabled={!props.editMode}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  props.onChangeRoundupHeadline(props.roundupHeadline + "$sportname")
                }
              >
                Sport
              </button>
              <button
                className="gender-sport-button"
                type="button"
                disabled={!props.editMode}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  props.onChangeRoundupHeadline(props.roundupHeadline + "$gender_sportname")
                }
              >
                GenderSport
              </button>
              <button
                className="gender-sport-button"
                type="button"
                disabled={!props.editMode}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  props.onChangeRoundupHeadline(props.roundupHeadline + "$date")
                }
              >
                Date
              </button>
              </div>
            )}

            </div>
        <button
          className="btn btn-primary align-self-start"
          style={{ height: "55px", width: "118px" }}
          type="button"
          onClick={props.onSubmitSave}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default RoundupTab;
