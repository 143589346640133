import { ChangeEventHandler, useEffect, useRef } from 'react';
import cn from 'classnames';
import './Checkbox.scss';
import { ReactComponent as TipIcon } from '../../icons/tip_white.svg';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';
import classNames from 'classnames';

export enum CheckboxEnum {
  Checkbox,
  Radio,
}

interface Props {
  id?: string;
  name?: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  type?: CheckboxEnum;
  onChange: (isChecked: boolean) => void;
  // onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  labelColor?: string;
  isError?: boolean;
  isBadge?: boolean;
  labelId?: string;
  label: JSX.Element | string;
  theme?: '' | 'primary';
}

export const Checkbox = ({
  type = CheckboxEnum.Checkbox,
  checked,
  onChange,
  onBlur,
  className = '',
  labelColor,
  isError = false,
  isBadge = false,
  disabled = false,
  indeterminate = false,
  id,
  name,
  label,
  labelId = '',
  theme = '',
}: Props) => {
  const isCheckbox = type === CheckboxEnum.Checkbox;

  const cRef = useRef<any>();

  useEffect(() => {
    if (cRef.current) {
      setTimeout(() => {
        cRef.current.indeterminate = indeterminate;
      }, 1000);
    }
  }, [cRef, indeterminate]);

  const labelStyles = {
    ...(labelColor ? {
      color: labelColor,
      borderColor: labelColor,
    } : {}),
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };
  return (
    <div className={`Checkbox ${className} ${theme}`}>
      <label
        className={cn('d-flex align-items-center Checkbox__container', {
          Checkbox__error: isError,
        })}
        data-testid={name}
      >
        <input
          id={id}
          name={name}
          type={isCheckbox ? 'checkbox' : 'radio'}
          onChange={handleChange}
          checked={checked || indeterminate}
          disabled={disabled}
          onBlur={onBlur}
          data-testid={'checkbox_' + name}
        />
        <div
          className={cn('Checkbox__input', {
            'Checkbox__input--radio': !isCheckbox,
            'Checkbox__input--checkbox': isCheckbox,
          })}
        >
          {isCheckbox && (
            indeterminate ? <MinusIcon className='icon-indeterminate' /> : <TipIcon />
          )}
        </div>
        <div
          className={classNames('Checkbox__label', {
            'opacity-50': !checked,
            'label__badge': isBadge,
          })}
          style={labelStyles}
          id={labelId}
        >
          {label}
        </div>
      </label>
    </div>
  );
};


