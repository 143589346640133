import classNames from 'classnames';
import de from 'date-fns/esm/locale/de/index.js';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import './Input.scss';
interface Props {
  value: string;
  type?: string;
  onChange: ChangeEventHandler;
  name: string;
  label?: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  error?: boolean | string | undefined;
  onBlur?: FocusEventHandler;
  style?: object;
}

export const Input = ({
  value,
  type = 'text',
  onChange,
  onBlur,
  name,
  label,
  className = '',
  required = false,
  readOnly = false,
  placeholder = '',
  error,
  style = {},
}: Props) => {
  return (
    <div className={`Input ${className}`} style={style}>
      {!!label && <div className=' fw-bold mb-2 Input__label '>{label}</div>}
      <div className='d-flex justify-content-between  Input__container  '>
        {/* <div className='flex-grow-1' > */}
          <input
            value={value}
            onChange={onChange}
            type={type}
            className={classNames(' w-100 Input__input ', {
              'Input__input--error': error,
            })}
            name={name}
            onBlur={onBlur}
            readOnly={readOnly}
            placeholder={placeholder} 
          />
        {/* </div> */}
        <div
            style={{
              width:"10px",
            
              paddingLeft: "9px",
              color: '#ee465a',
              opacity:required?1:0,
            }}
          >
            *
          </div>
       
      
      </div>
      {error && <div className='Input__error text-end'>{error}</div>}
    </div>
  );
}

export default Input;