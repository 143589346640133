import "./FirstPage.scss";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PublicationFrame from "../../components/PublicationFrame/PublicationFrame";
import AddNewPublication from "../../components/PublicationFrame/AddNewPublication";
import Img1 from "../../imgs/Frame 2.png";
import { useModal } from "../../hooks/useModal";
import Modal from "../../components/Modal";
import styled from "styled-components";
import { FormikErrors, useFormik } from "formik";
import { ReactComponent as PlaceHolder } from "./../../icons/imagePlaceholder.svg";
import {
  getAllClients,
  addPublicationClientNoFound,
  addNewClient,
} from "../../core/api";
import { Input } from "../../components";
import { Client } from "../../core/types";

export default function FirstPage() {
  const history = useHistory();
  const [currentClientName, setCurrentClientName] = useState("");
  const [clientList, setClientList] = useState<Client[]>([]);

  useEffect(() => {
    getAllClients().then((res) => {
      setClientList(res.data);
    });
  }, []);

  const {
    isModalOpened: isClientModalOpened,
    closeModal: closeClientModal,
    openModal: openClientModel,
  } = useModal();

  const {
    isModalOpened: ispublicationModalOpened,
    closeModal: closePublicationModal,
    openModal: openpublicationModal,
  } = useModal();

  const clientFormik = useFormik<{ clientName: string }>({
    initialValues: {
      clientName: "",
    },
    validate: (values) => {
      const errors: FormikErrors<{ clientName: string }> = {};

      if (!values.clientName) {
        errors.clientName = "Required";
      }

      return errors;
    },
    onSubmit: (values) => {
      const data = {
        name: values.clientName,
      };
      addNewClient(data)
        .then((res) => {
          getAllClients().then((res) => {
            setClientList(res.data);
            closeClientModal();

          });
       })
        .catch((err) => {
          console.error(err);
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "dark",
          });
        });
    },
  });

  const publicationFormik = useFormik<{
    // [x: string]: any;
    publicationName: string;
  }>({
    initialValues: {
      publicationName: "",
    },
    validate: (values) => {
      const errors: FormikErrors<{ publicationName: string }> = {};

      if (!values.publicationName) {
        errors.publicationName = "Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      closePublicationModal()
      const data = {
        client_name: currentClientName,
        name: values.publicationName,
      };
      addPublicationClientNoFound(data)
        .then((res) => {
          history.push(`/configuration?editMode=true&publication=${data.name}`);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "dark",
          });
        });
    },
  });

  return (
    <>
      <div className="admin-dashboard d-flex flex-column">
        <div className="dashboard-header  d-flex  align-items-baseline justify-content-between ">
          <h3 className="overview-bold text--orange">Overview</h3>
          <button
            className="btn--outline  "
            onClick={() => {
              openClientModel();
            }}
          >
            Add New Client
          </button>
        </div>

        {clientList.map((c, index) => {
          return (
            <div key={index} className="mb-4 mt-4">
              <h1 className="client-name-heading">{c.name}</h1>
              <h2 className="text-publication">Publications</h2>
              <div className="content-container">
                <AddNewPublication
                  handleClick={() => {
                    setCurrentClientName(c.name);
                    openpublicationModal();
                  }}
                />

                {c.publications.map((p, index) => {
                  return (
                    <PublicationFrame
                      handleClick={() => {
                        history.push(`/configuration?editMode=true&publication=${p}`);
                      }}
                      key={index}
                      publicationName={p}
                      FrameImg={Img1}
                    ></PublicationFrame>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        isOpened={isClientModalOpened}
        className="clientModal"
        closeModal={closeClientModal}
        
      >
        <div className="modal-content">
          <ModalTitle className="fw-bold m text--dark">Add a Client</ModalTitle>
          <p className=" text-justify  mt-2  client-modal-text">
            Clients are parent organizations, like Gannett or The
            Atlanta-Journal Constitution. A Publication is a publishing outlet.
            A Client and a Publication can have the same name, as is the case
            with The AJC.
          </p>
          <form
            className="w-100 d-flex flex-column"
            onSubmit={clientFormik.handleSubmit}
          >
            <Input
              onBlur={clientFormik.handleBlur}
              value={clientFormik.values.clientName}
              onChange={clientFormik.handleChange}
              name="clientName"
              className="mb-4 light clientModalInput"
              required={true}
              style={{ fontSize: "22px" }}
              error={
                clientFormik.touched.clientName &&
                clientFormik.errors.clientName
              }
            />
            <button className="btn align-self-center" type="submit">
              Save and Add a Publication
            </button>
          </form>
        </div>
      </Modal>
      <Modal
        isOpened={ispublicationModalOpened}
        closeModal={closePublicationModal}
      >
        <div className="modal-content">
          <ModalTitle className="fw-bold  text--dark mb-3">
            Add a Publication
          </ModalTitle>

          <form
            className="w-100 px-4 d-flex flex-column publicationForm"
            onSubmit={publicationFormik.handleSubmit}
          >
            <span className="fw-bold text--dark  mb-2 subtitle">
              General Info
            </span>
            <Input
              onBlur={publicationFormik.handleBlur}
              value={publicationFormik.values.publicationName}
              onChange={publicationFormik.handleChange}
              name="publicationName"
              label="Publication Name"
              className="mb-4 light"
              required={true}
              error={
                publicationFormik.touched.publicationName &&
                publicationFormik.errors.publicationName
              }
            />
            <div className="image-placeholder">
              <span className="fw-bold text--dark d-block mb-2">
                Publication Logo
              </span>
              <div className="placeholder-container mb-2">
                <PlaceHolder />
              </div>
              <button className="btn my-3 fw-bold " style={{ height: "55px" }}>
                Upload Image
              </button>
            </div>
            <div
              className=" w--10 d-flex  justify-content-between mt-2 buttonContainer "
              style={{ minHeight: "55px", paddingRight: "60px" }}
            >
              <button
                className="btn fw-bold "
                type="submit"
              >
                Configure High School Sports
                
              </button>
              <button
                type="button"
                className="btn btn--outline"
                onClick={() => {
                  closePublicationModal();
                }}
              >
                Save for Later
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

const ModalTitle = styled.div`
  font-size: 32px;
  line-height: 48px;
  color: #072136;
`;
