import React from 'react'
import {ReactComponent as PlusIcon} from './../../icons/plus-large.svg'
import './AddNewPublication.scss'

type props={
  handleClick:()=>void
  
}

const AddNewPublication = (props:props) => {

  return (
    <div className="np_frame"  onClick={()=>{props.handleClick()}} >

    <div className="np_imageContainer"  >
    <PlusIcon className='np_plusIcon' />
    </div>
    <div className="np_description-box">
      <p className="np_description"> 
    Add New Publication
      </p>
    
    </div>
  </div>
  )
}

export default AddNewPublication