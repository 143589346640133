import { ChangeEvent, ChangeEventHandler, MouseEventHandler, MouseEvent, useEffect, useState } from "react";
import { ReactComponent as Alert } from "./../../icons/Alert.svg";
import { ReactComponent as Bin } from "./../../icons/Bin.svg";
import { toast } from "react-toastify";
import "./Configuration.scss";
import styled from "styled-components";
import StandardTab from "../PublicatoinConfigTab/StandardTab";
import RoundupTab from "../PublicatoinConfigTab/RoundupTab";
import ContentDeliveryTab from "../PublicatoinConfigTab/ContentDeliveryTab";
import AdvanceTab from "../PublicatoinConfigTab/AdvanceTab";
import { useModal } from "../../hooks/useModal";
import Modal from "../../components/Modal";
import "react-quill/dist/quill.snow.css";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { useLocation, useHistory } from "react-router-dom";
import {OPTION, PUBLICATION, BLOX, WORDPRESS, BRIGHTSPOT, CIAT, PUBSYSTEM } from '../../core/types';
import { GENDER_LEVEL, SPORTS, SEOMETAS, ROUNDUPMETAS } from "../../constants/constants";
import { updatePublication, getPublication, testPublishConnection, DeletePublication } from "../../core/api"
import { FALSE } from "sass";

const Configuration = () => {

  const initialContent = `<p><strong>This content will be at the bottom of every article. We recommend making this an advertising opportunity. Below is an example, and feel free to make it your own.</strong></p> <br/> <p><i>You're reading a news brief powered by <a href=\"https://scorestream.com/gettheapp\">ScoreStream</a>, the world leader in fan-driven sports results and conversation. To see more game results from your favorite team, download the ScoreStream app and join over 10 million users nationwide who share the scores of their favorite teams with one another in real-time.</i></p>
  `;
  const [activatedTab, setActivatedTab] = useState("coverage");

  const [publicationDetails, SetPublicationDetails] = useState<PUBLICATION>();
  const [outletincomplete, setOutletIncomplete] = useState(false);
  const [populatePub, setPopulatePub] = useState(true);
  const [editorValue, setEditorValue] = useState(publicationDetails?.trailer_boiler_plate?.html ? publicationDetails?.trailer_boiler_plate?.html :initialContent);
  const [editMode, setEditMode] = useState(true);

  // For rounduptabs
  const [stateAthleticValue, setStateAthleticValue] = useState(publicationDetails?.state_association);
  const [autoPublishValue, setAutoPublishValue] = useState(publicationDetails?.auto_publish)
  const [autoPublishRoundupValue, setAutoPublishRoundupValue] = useState(publicationDetails?.auto_publish_roundup)
  const [publishRoundUpOnlyValue, setPublishRoundupOnlyValue] = useState(publicationDetails?.publish_roundups_only)
  const [apStyleRoundUpValue, setApStyleRoundUpValue] = useState(publicationDetails?.ap_format_roundup)
  const [roundupHeadlineFormat, setRoundupHeadlineFormat] = useState(publicationDetails?.round_up_headline ? publicationDetails?.round_up_headline : '$gender $sportname Roundup - $date')
  const [checkedRoundupTagList, setCheckedRoundupTagList] = useState(ROUNDUPMETAS);

  // For ContentDelivery
  const [contentMethodValue, setContentMethodValue] = useState("email");
  const [contentEmailContacts, setcontentEmailContacts] = useState("");
  const [cmsValue, setCmsMethodValue] = useState("");
  const [apiKeyValue, setApiKeyValue] = useState("");
  const [apiSecretValue, setApiSecretValue] = useState("");
  const [authorNameValue, setAuthorNametValue] = useState("");
  const [additionalTagValue, setAdditionalTagValue] = useState("");
  const [urlSlugValue, setUrlSlugValue] = useState("");
  const [apiGetUrlValue, setApiGetUrlValue] = useState("");
  const [apiPostUrlValue, setApiPostUrlValue] = useState("");
  const [testPubButtonColor, setTestPubButtonColor] = useState("");
  const [testPubButtonStatus, setTestPubButtonStatus] = useState(true);

  const [deepLink, setDeepLink] = useState(true);
  const [publishStatus, setPublishStatus] = useState(false);
  const [checkedSeoMetaList, setCheckedSeoMetaList] = useState(SEOMETAS);
  
  //Brightspot

  const [brightSpotFormat, setBrightSpotFormat] = useState("");
  const [brightSpotType, setBrightSpotType] = useState("");

  //CIAT
  const [ciatSiteCode, setCiatSiteCode] = useState("");
  const [ciatBy, setCiatBy] = useState("");
  const [ciatBylineSource, setCiatByLineSource] = useState("");
  const [ciatAssociationIds, setCiatAssociationIds] = useState("");
  const [ciatPaywallType, setCiatPaywallType] = useState("");

  //For Advancedtab
  const [timezoneValue, setTimezoneValue] = useState(publicationDetails?.timezone);
  const [hoursValue, setHoursValue] = useState(publicationDetails?.publishing_time? publicationDetails?.publishing_time.split(":")[0]: "10");
  const [minutesValue, setMinutesValue] = useState(publicationDetails?.publishing_time ? publicationDetails?.publishing_time.split(":")[1]: "10");
  const [amPMValue, setamPMValue] = useState(publicationDetails?.publishing_time ? publicationDetails?.publishing_time.split(" ")[0]: "PM");
  const [isCheckedPausePublish, setCheckPausePublish] = useState(false);

  //For Standardtab
  const [checkedSportList, setCheckedSportList] = useState(SPORTS);
  const [checkedGenderLevelList, setCheckedGenderLevelList] = useState(GENDER_LEVEL);

  const [coverageType, setCoverageType] = useState(publicationDetails?.coverage ? publicationDetails?.coverage[0]?.definition : "");

  const [checkedStateList, setCheckedStateList] = useState<OPTION[]>([]);
  const [checkedCityList, setCheckedCityList] = useState<OPTION[]>([]);
  const [checkedCityState, setCheckedCityState] = useState("");
  const [includedCities, setIncludeCities] = useState(publicationDetails?.coverage ? publicationDetails?.coverage[0]?.id_lists : []);
  const [cityOptions, setCityOptions] = useState([]);
  const [includeCityState, setIncludeCityState] = useState(false);
  const [checkedIncludeCityList, setCheckedIncludeCityList] = useState<OPTION[]>([]);

  const [getCitiesButtonState, setGetCitiesButtonState] = useState(true);
  const [cityReload, setCityReload] = useState(false);
  const [cityName, setCityName] = useState<string>("");

  const [isCheckedGenAI, setCheckGenAI] = useState(publicationDetails?.gen_ai);
  const [isCheckedSeoOpt, setCheckSeoOpt] = useState(publicationDetails?.default_lede);
  const [isCheckedHeadlineScore, setCheckHeadlineScore] = useState(publicationDetails?.headline_score);
  const [isCheckedGenderSport, setCheckGenderSport] = useState(publicationDetails?.gender_headline);
  const [isCheckedCityName, setCheckCityName] = useState(!publicationDetails?.no_city_name);
  const [isCheckedSportDetailed, setSportDetailed] = useState(publicationDetails?.sports_detailed);
  const [isCheckedContentDynamic, setCheckContentDynamic] = useState(publicationDetails?.content_dynamics === 'high' ? true : false);

  const [language, setLanguage] = useState<string>("english");

  const [save, setSave] = useState(false);

  const location = useLocation();
  // const isEditMode = new URLSearchParams(location.search).get('editMode');
  const currentPub = new URLSearchParams(location.search).get('publication');
  const [pubName, setPubName] = useState(currentPub)

    //RSS
    const [rssFeedUrl, setRssFeedUrl] = useState("https://ledeairss.com/category/" + pubName?.replaceAll(" ", "_") + "/feed");

  // if (currentPub){
  //   setPubName(currentPub)
  // }

  function setAllFlags(pub_details: any)
  {

    //standard tab
    setCheckedGenderLevelList(pub_details?.coverage[0].sports_level.map((value: string) => {
      return {'label': GENDER_LEVEL.find((element) => element.value === value.toString())?.label, 'value': value.toString(), 'isChecked': true}
    }));

    setCheckedSportList(pub_details?.coverage[0].sport.map((value: string) => {
      return {'label': SPORTS.find((element) => element.value === value.toString())?.label, 'value': value.toString(), 'isChecked': true}
    }));
    setCoverageType(pub_details?.coverage[0].definition);

    if (pub_details?.coverage[0].definition == 'cities') {
      setIncludeCities(pub_details?.coverage[0].id_lists.map((value:string) => {
        return {'label': value, 'value': value, 'isChecked': false}
      }));
      setCheckedIncludeCityList(pub_details?.coverage[0].id_lists.map((value: any) => {
        return {'label': value, 'value': value, 'isChecked': true}
      }));
      setIncludeCityState(true);
      setCityReload(false);
    }
    else{
      setCheckedStateList(pub_details?.coverage[0].id_lists.map((value:string) => {
        return {'label': value, 'value': value, 'isChecked': true}
      }));
      setIncludeCityState(false);
      setCityReload(false);
    }
    setCheckCityName(!pub_details?.no_city_name);
    setSportDetailed(pub_details?.sports_detailed);
    setCheckContentDynamic(pub_details?.content_dynamics === "high" ? true : false);
    setCheckGenAI(pub_details?.gen_ai);
    setCheckGenderSport(pub_details?.gender_headline)
    setLanguage(pub_details?.article_language)
    setEditorValue(pub_details?.trailer_boiler_plate?.html)
    setCheckHeadlineScore(pub_details?.headline_score)
    setCheckSeoOpt(pub_details?.default_lede)

    //roundup tab
    setStateAthleticValue(pub_details?.state_association);
    setApStyleRoundUpValue(pub_details?.ap_format_roundup);
    setPublishRoundupOnlyValue(pub_details?.publish_roundups_only);
    setAutoPublishRoundupValue(pub_details?.auto_publish_roundup);
    setRoundupHeadlineFormat(pub_details?.round_up_headline);

    //Advanced tab
    setCheckSeoOpt(pub_details?.default_lede);
    setTimezoneValue(pub_details?.timezone);
    setHoursValue(pub_details?.publishing_time ? pub_details?.publishing_time.split(":")[0]: "10");
    setMinutesValue(pub_details?.publishing_time ? pub_details?.publishing_time.split(":")[1].split(" ")[0]: "10");
    setamPMValue(pub_details?.publishing_time ? pub_details?.publishing_time.split(" ")[1] : "PM");
    setCheckPausePublish(pub_details?.pause_publishing);

    //content delivery

    setcontentEmailContacts(pub_details?.additional_contact_emails_for_articles.join(", "));
    setDeepLink(pub_details?.deep_link);

    setOutletIncomplete(pub_details?.outlet_incomplete);

    let publishing_system = [];
    
    if (pub_details.publishing_system) {
      publishing_system = pub_details.publishing_system[0];
    }


    if (publishing_system){

      if (publishing_system?.rss_feed_url){
        setContentMethodValue('rss');
        setRssFeedUrl(publishing_system?.rss_feed_url);
      }
      else{

        setCmsMethodValue(publishing_system?.system_name);
        setContentMethodValue('api');
        let seoMetaList = publishing_system?.json_payload_structure?.keywords?.filter((value: string) => SEOMETAS.map((nc) => nc.value).indexOf(value) !== -1)
        let roundupMetaList = publishing_system?.round_up_tags?.filter((value: string) => ROUNDUPMETAS.map((nc) => nc.value).indexOf(value) !== -1)

        if (seoMetaList){
          setCheckedSeoMetaList(seoMetaList.map((value: string) => {
            return {'label': SEOMETAS.find((element) => element.value === value.toString())?.label, 'value': value.toString(), 'isChecked': true}
          }));
        }

        if (roundupMetaList){
          setCheckedRoundupTagList(roundupMetaList.map((value: string) => {
            return {'label': ROUNDUPMETAS.find((element) => element.value === value.toString())?.label, 'value': value.toString(), 'isChecked': true}
          }));
        }

        let additional_tag_values = publishing_system?.json_payload_structure?.keywords?.filter((value: string) => SEOMETAS.map((nc) => nc.value).indexOf(value) === -1)
  
        if (additional_tag_values){
          setAdditionalTagValue(additional_tag_values.join(", "));
        }
        setPublishStatus(publishing_system?.json_payload_structure.published);
        setAuthorNametValue(publishing_system?.json_payload_structure.authors);
  
        if (publishing_system?.system_name == 'blox'){
          setApiPostUrlValue(publishing_system?.json_payload_structure.post_url);
          setApiGetUrlValue(publishing_system?.json_payload_structure.get_url);
          setApiKeyValue(publishing_system?.json_payload_structure.webservice_key);
          setApiSecretValue(publishing_system?.json_payload_structure.webservice_pwd);
          setUrlSlugValue(publishing_system?.json_payload_structure.sections);
        }
        else if (publishing_system.system_name == 'wordpress'){
          setApiPostUrlValue(publishing_system?.json_payload_structure.post_url);
          setApiGetUrlValue(publishing_system?.json_payload_structure.wp_logon_url);
          setApiKeyValue(publishing_system?.json_payload_structure.wp_user);
          setApiSecretValue(publishing_system?.json_payload_structure.wp_pwd);
          setPublishStatus(publishing_system?.json_payload_structure.published);
          setUrlSlugValue(publishing_system?.json_payload_structure.sections[0]);
        }
        else if(publishing_system.system_name == 'brightspot'){
          setApiPostUrlValue(publishing_system?.json_payload_structure.post_url);
          setApiGetUrlValue(publishing_system?.json_payload_structure.root_domain);
          setApiKeyValue(publishing_system?.json_payload_structure.x_api_key);
          setApiSecretValue(publishing_system?.json_payload_structure.x_import_session);
          setPublishStatus(publishing_system?.json_payload_structure.published);
          setUrlSlugValue(publishing_system?.json_payload_structure.sections);
          setBrightSpotFormat(publishing_system?.json_payload_structure?.brightspot_format);
          setBrightSpotType(publishing_system?.json_payload_structure?.brightspot_type);
        }
        else if(publishing_system.system_name == 'ciat'){
          setApiPostUrlValue(publishing_system?.json_payload_structure.post_url);
          setApiGetUrlValue(publishing_system?.json_payload_structure.get_url);
          setApiKeyValue(publishing_system?.json_payload_structure.x_api_key);
          setUrlSlugValue(publishing_system?.json_payload_structure.sections);
  
          setCiatAssociationIds(publishing_system?.json_payload_structure?.ciatAssociationIds.join(", "));
          setCiatBy(publishing_system?.json_payload_structure?.ciat_by);
          setCiatByLineSource(publishing_system?.json_payload_structure?.ciat_byline_source);
          setCiatPaywallType(publishing_system?.json_payload_structure?.ciat_paywall_type);
          setCiatSiteCode(publishing_system?.json_payload_structure?.ciat_site_code);
      }
    }
      }

}


  // useEffect(() => {
  //   isEditMode === "true" ? setEditMode(true) : setEditMode(false);
  // }, []);

  useEffect(() => {
    if (populatePub){
      let currentPub = new URLSearchParams(location.search).get('publication');

      if (!currentPub){
        if (publicationDetails && 'name' in publicationDetails)
          currentPub = pubName
      }

      if (currentPub){
      getPublication(currentPub)
      .then((res) => {
        SetPublicationDetails(res.data);
        setAllFlags(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Missing Required Fields", {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
      });
      setPopulatePub(false);
    }
  }
  }, [populatePub]);

  useEffect(() => {
    let publishing_systems = []
    let autopublish = false
    if (contentMethodValue === 'api'){
        setAutoPublishValue(true);
        autopublish = true;
        if (cmsValue === 'blox'){
          let blox_details = get_publishing_data();
            let pub_system: PUBSYSTEM = {
              active: true,
              system_name: 'blox',
              system_type: 'blox',
              round_up_id: pubName?.replaceAll(' ' , '_') + '_$gender$_$sportname$_$date$',
              round_up_tags: checkedRoundupTagList.map(nc => nc.value),
              json_payload_structure: blox_details
            }
            publishing_systems.push(pub_system)
        }
        else if (cmsValue === 'wordpress'){
            let wp_details = get_publishing_data();
            let pub_system: PUBSYSTEM = {
              active: true,
              system_name: 'wordpress',
              system_type: 'wordpress',
              round_up_id: pubName?.replaceAll(' ' , '_') + '_$gender$_$sportname$_$date$',
              round_up_tags: checkedRoundupTagList.map(nc => nc.value),
              json_payload_structure: wp_details
            }
            publishing_systems.push(pub_system)
        }
        else if (cmsValue === 'brightspot'){
          let brightspot_details = get_publishing_data();
          let pub_system: PUBSYSTEM = {
            active: true,
            system_name: 'brightspot',
            system_type: 'brightspot',
            round_up_id: pubName?.replaceAll(' ' , '_') + '_$gender$_$sportname$_$date$',
            round_up_tags: checkedRoundupTagList.map(nc => nc.value),
            json_payload_structure: brightspot_details
          }
          publishing_systems.push(pub_system)
        }
        else if(cmsValue === 'ciat'){
          let ciat_details  = get_publishing_data();
          let pub_system: PUBSYSTEM = {
            active: true,
            system_name: 'ciat',
            system_type: 'ciat',
            round_up_id: pubName?.replaceAll(' ' , '_') + '_$gender$_$sportname$_$date$',
            round_up_tags: checkedRoundupTagList.map(nc => nc.value),
            json_payload_structure: ciat_details
          }
          publishing_systems.push(pub_system)
        }
      }
    else if (contentMethodValue === 'rss'){
      setAutoPublishValue(true);
      autopublish = true;
      let rss_details:PUBSYSTEM = {
        active: true,
        system_name: 'rss',
        system_type: 'rss',
        round_up_id: pubName?.replaceAll(' ' , '_') + '_$gender$_$sportname$_$date$',
        round_up_tags: checkedRoundupTagList.map(nc => nc.value),
        rss_feed_url: rssFeedUrl
      }
      publishing_systems.push(rss_details);
      } 

    if (save){
      const publication_value: PUBLICATION = {
        name: currentPub,
        auto_publish: autopublish,
        auto_publish_roundup: autoPublishRoundupValue,
        publish_roundups_only: publishRoundUpOnlyValue,
        gen_ai: isCheckedGenAI,
        sports_detailed: isCheckedSportDetailed,
        publishing_time: hoursValue + ':' + minutesValue + ' ' + amPMValue,
        timezone: timezoneValue,
        ap_format_roundup: apStyleRoundUpValue,
        no_city_name: !isCheckedCityName,
        headline_score: isCheckedHeadlineScore,
        trailer_boiler_plate: {'html': editorValue, 'text': 'A sample text'},
        state_association: stateAthleticValue,
        default_lede: isCheckedSeoOpt,
        content_dynamics: isCheckedContentDynamic ? "high" : "low",
        additional_contact_emails_for_articles: contentEmailContacts ? contentEmailContacts.split(", ") : [],
        sports_interested: checkedSportList.map((element) => element.value),
        deep_link: deepLink,
        gender_headline: isCheckedGenderSport,
        article_language: language,
        round_up_headline: roundupHeadlineFormat,
        pause_publishing: isCheckedPausePublish,
        coverage: [
          {

            id_lists: coverageType === "state" ? checkedStateList.map((element) => element.value) : checkedCityList ?.concat(includedCities || [{value: ''}])?.map((element) => element.value),
            definition: coverageType ? coverageType : "",
            sport: checkedSportList.map((element) => element.value),
            sports_level: checkedGenderLevelList.map((element) => Number(element.value)),
          }
        ],
        publishing_system: publishing_systems
      }

      updatePublication(publication_value)
      .then((res) => {
        console.log(res);
        toast.info("Updates Saved Successfully", {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
        setPubName(res.name);
        window.history.pushState({}, '', `/configuration?publication=${currentPub}`)
        // window.location.href = '/configuration?publication=' + currentPub;
        SetPublicationDetails(publicationDetails);
        setAllFlags(res);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
      });
      setSave(false);
    }
  },[save]);

  function get_publishing_data(){

    let keywords:string[] = checkedSeoMetaList.map(nc => nc.value);

    if (additionalTagValue){
      keywords = keywords.concat(additionalTagValue.split(", "));
    }
    if (cmsValue === 'blox'){
      let blox_details: BLOX = {
        authors : authorNameValue,
        content : "",
        get_url : apiGetUrlValue,
        post_url : apiPostUrlValue,
        keywords : keywords,
        published : publishStatus,
        sections : urlSlugValue,
        webservice_key : apiKeyValue,
        webservice_pwd : apiSecretValue,
        start_time : "",
        id : pubName?.replaceAll(' ', '_') + '_ledeai_$game_id',
        title: "",
        type: "Article"
        }
      return blox_details;
    }
    else if (cmsValue === 'wordpress'){
      let wordpress_sections: string[] = [urlSlugValue]
      let wp_details: WORDPRESS = {
        authors : authorNameValue,
        content : "",
        wp_logon_url : apiGetUrlValue,
        post_url : apiPostUrlValue,
        keywords : keywords,
        published : publishStatus,
        sections : wordpress_sections,
        wp_user : apiKeyValue,
        wp_pwd : apiSecretValue,
        id : pubName?.replaceAll(' ', '_') + '_ledeai_$game_id',
        title: "",
        type: "Article"
        }
      return wp_details;
    }
    else if (cmsValue === 'brightspot'){
      let brightspot_details : BRIGHTSPOT = {
        authors: authorNameValue,
        content: "",
        brightspot_format: brightSpotFormat,
        brightspot_type: brightSpotType,
        published: publishStatus,
        sections: urlSlugValue,
        post_url: apiPostUrlValue,
        x_api_key: apiKeyValue,
        id : pubName?.replaceAll(' ', '_') + '_ledeai_$game_id',
        title: "",
        type: "Article",
        x_import_session: apiSecretValue,
        root_domain: apiGetUrlValue ? apiGetUrlValue : ""
      }
      return brightspot_details;
    }
    else if(cmsValue === 'ciat'){
      let ciat_details : CIAT = {
        authors: authorNameValue,
        content: "",
        published: publishStatus,
        keywords: keywords,
        sections: urlSlugValue,
        post_url: apiPostUrlValue,
        x_api_key: apiKeyValue,
        id : pubName?.replaceAll(' ', '_') + '_ledeai_$game_id',
        title: "",
        type: "Article",
        get_url: apiGetUrlValue ? apiGetUrlValue : "",
        ciat_association_ids: ciatAssociationIds.split(", "),
        ciat_by: ciatBy,
        ciat_byline_source: ciatBylineSource,
        ciat_paywall_type: ciatPaywallType,
        ciat_site_code: ciatSiteCode
      }
      return ciat_details;
  }
}

const testPublish: MouseEventHandler<HTMLButtonElement> = (
  e: MouseEvent<HTMLButtonElement>
) => {

 let data:any = get_publishing_data();

 if (data){
  data.system = cmsValue;
 }

 if (data){
  setTestPubButtonStatus(false);
  setTestPubButtonColor("");
  testPublishConnection(data)      
  .then((res) => {
    console.log(res);
    setTestPubButtonColor("#0000FF");
    toast.info("Test Connection Successful", {
      position: "top-right",
      autoClose: 4500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "dark",
    });
  })
  .catch((err) => {
    console.error(err);
    setTestPubButtonColor("#FF0000");
    toast.error(err.message, {
      position: "top-right",
      autoClose: 4500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "dark",
    });
  })
  .finally(() => {
    setTestPubButtonStatus(true);
  });
 }
 }

  const handleContentMethodChange = (value: string) => {
    setContentMethodValue(value);
  };

  const handleCoverageTypeChange = (value: string) => {
    setGetCitiesButtonState(true);
    if (value == 'cities')
    {
      setCheckedStateList([]);

      if (publicationDetails?.coverage[0] && 'definition' in publicationDetails?.coverage[0] && publicationDetails?.coverage[0].definition == 'cities'){
        setIncludeCityState(true);
        setIncludeCities(publicationDetails?.coverage[0].id_lists.map((value:string) => {
          return {'label': value, 'value': value, 'isChecked': true}
        }));
        setCheckedIncludeCityList(publicationDetails?.coverage[0].id_lists.map((value: any) => {
          return {'label': value, 'value': value, 'isChecked': true}
        }));
      }

    }
    else {
      setCheckedCityState("");
      if (publicationDetails?.coverage[0] && 'definition' in publicationDetails?.coverage[0] && publicationDetails?.coverage[0].definition == 'state'){
        setCheckedStateList(publicationDetails?.coverage[0].id_lists.map((value:string) => {
          return {'label': value, 'value': value, 'isChecked': true}
        }));
        }
      setIncludeCityState(false);
    }
  setCoverageType(value);
  }

  const handleCityCheckedStateChange = (value: string) => {
    setCheckedCityState(value);
  }

  const handleCmsMethodChange = (value: string) => {
    setCmsMethodValue(value);
  };


  const handleApiKeyChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setApiKeyValue(e.target.value);
  };

  const handleApiSecretChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setApiSecretValue(e.target.value);
  };

  const handleAuthorNameChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setAuthorNametValue(e.target.value);
  };
  const handleAdditionalTagChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setAdditionalTagValue(e.target.value);
  };

  const handleUrlSlugChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setUrlSlugValue(e.target.value);
  };

  const handleApiGetUrlChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setApiGetUrlValue(e.target.value);
  };

  const handleApiPostUrlChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setApiPostUrlValue(e.target.value);
  };

  const handleHourChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10);
    console.log(intValue);
    if (!isNaN(intValue) && intValue >= 0 && intValue <= 12) {
      setHoursValue(value);
    } else setHoursValue("");
  };

  const handleMinutesChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10);

    if (!isNaN(intValue) && intValue >= 0 && intValue <= 59) {
      setMinutesValue(value);
    } else setMinutesValue("");
  };

  const handleAMPMChange: MouseEventHandler<HTMLInputElement> = (
    e: MouseEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.textContent == "AM"){
      setamPMValue("AM");
    }
    else{
      setamPMValue("PM");
    }
  };


  const handleCityNameChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setCityName(e.target.value);
  };

  const handleTimezoneChange = (value: string) => {
    setTimezoneValue(value);
  };

  const handlePausePublishChange = (value: boolean) => {
    setCheckPausePublish(value);
  };

  const handleHeadlineScoreChange = (value: boolean) => {
    setCheckHeadlineScore(value);
  }
  const handleGenderSportChange = (value: boolean) => {
    setCheckGenderSport(value);
  }

  const handleSportDetailedChange = (value: boolean) => {
    setSportDetailed(value);
  }
  
  const handleContentDynamicChange = (value: boolean) => {
    setCheckContentDynamic(value);
  }
  const {
    isModalOpened: isConfirmModalOpened,
    closeModal: closeConfirmModal,
    openModal: openConfirmModel,
  } = useModal();

  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };
  
  const handleStateAthletic: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setStateAthleticValue(e.target.value);
  };

  const handleChangeEmailContacts: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setcontentEmailContacts(e.target.value);
  };

  const handleChangeGenAI = (value : boolean) => {
    setCheckGenAI(value);
  }

  const handleChangeSeoOpt = (value : boolean) => {
    setCheckSeoOpt(value);
  }
  const handleCitiNameChange = (value : boolean) => {
    setCheckCityName(value);
  }

  
  const onSubmitsave = () => {
    setSave(true);
  }

  return (
    <>
      <div className="configuration-container d-flex flex-column ">
        <div className="configuration-header  d-flex  flex-column ">
          <span className="fw-bold text--orange">Overview</span>
          <h2 className="fw-bold text--dark page-heading">{currentPub}</h2>
          <div className="edit-bar d-flex justify-content-between ">
            {
              outletincomplete && (
              <span className="alert-span d-flex align-items-center">
                <Alert className="mr--10" />
                Outlet Incomplete
              </span>
              )
            }

            <div className="edit-right-bar d-flex   ">
              {!editMode && (
                <span
                  onClick={() => {
                    editMode ? setEditMode(false) : setEditMode(true);
                    setPopulatePub(true);
                  }}
                  className="archive-span d-flex align-items-center text--underline  cursor--pointer mx-5 text--primary "
                >
                  <EditIcon className="mr--10" />
                  Edit
                </span>
              )}
              <span className="archive-span d-flex  text--underline align-items-center cursor--pointer text--primary" onClick={ (e: MouseEvent<HTMLSpanElement>) => DeletePublication(pubName) }>
                <Bin className="mr--10"/>
                Archive
              </span>
            </div>
          </div>
        </div>
        <div className="configuration-panel-head my-3">
          <h2 className="cp-h2-heading ">Publication Configuration</h2>
          <h3 className="cp-h3-heading">High School Sports</h3>
        </div>
        <div
          className="px-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span
            className={
              activatedTab !== "coverage"
                ? "fw-bold cursor--pointer text--light"
                : " fw-bold cursor--pointer text--primary"
            }
            onClick={() => {
              setActivatedTab("coverage");
            }}
          >
            Coverage Details
          </span>
          <span
            className={
              activatedTab !== "roundup"
                ? "fw-bold cursor--pointer text--light"
                : " fw-bold cursor--pointer text--primary"
            }
            onClick={() => {
              setActivatedTab("roundup");
            }}
          >
            Roundup Settings
          </span>
          <span
            className={
              activatedTab !== "content"
                ? "fw-bold cursor--pointer text--light "
                : " fw-bold cursor--pointer text--primary"
            }
            onClick={() => {
              setActivatedTab("content");
            }}
          >
            Content Delivery Settings
          </span>
          <span
            className={
              activatedTab !== "advance"
                ? "fw-bold cursor--pointer text--light"
                : " fw-bold cursor--pointer text--primary"
            }
            onClick={() => {
              setActivatedTab("advance");
            }}
          >
            Advanced Settings
          </span>
        </div>
        <hr className="text--orange m-0 mb-4 mt-2" />
        {activatedTab === "coverage" && (
          <StandardTab
            editorValue={editorValue}
            getCitiesButtonState={getCitiesButtonState}
            setgetCitiesButtonState={setGetCitiesButtonState}
            onCoverageTypeChange={handleCoverageTypeChange}
            onEditorChange={handleEditorChange}
            editMode={editMode}
            cityReload={cityReload}
            cityOptions={cityOptions}
            cityName={cityName}
            checkedStates={checkedStateList}
            checkedcityState={checkedCityState}
            checkedCities={checkedCityList}
            includeCities={includedCities}
            includeCityState={includeCityState}
            language={language}
            onChangeLanguage={setLanguage}
            onChangeIncludeCityState={setIncludeCityState}
            checkedIncludeCities={checkedIncludeCityList}
            coverageType={coverageType}
            isCheckedGenAI={isCheckedGenAI}
            onChangeGenAI={handleChangeGenAI}
            isCheckedSeoOpt={isCheckedSeoOpt}
            onChangeSeoOpt={handleChangeSeoOpt}
            isCheckedHeadlineScore={isCheckedHeadlineScore}
            onChangeHeadlineScore={handleHeadlineScoreChange}
            isCheckedGenderSport={isCheckedGenderSport}
            onChangeGenderSport={handleGenderSportChange}
            isCheckedCityName={isCheckedCityName}
            onChangeCityName={handleCitiNameChange}
            isSportsDetailed={isCheckedSportDetailed}
            onChangeSportsDetailed={handleSportDetailedChange}
            isCheckedContentDynamic={isCheckedContentDynamic}
            onChangeContentDynamic={handleContentDynamicChange}
            onCityCheckedStateChange={handleCityCheckedStateChange}
            onCityCheckedListChange={setCheckedCityList}
            handleCityNameChange={handleCityNameChange}
            setCityOptions={setCityOptions}
            setCityReload={setCityReload}
            onSubmitSave={onSubmitsave}
          />
        )}
        {activatedTab === "roundup" && (
          <RoundupTab
            editMode={editMode}
            stateAthleticValue={stateAthleticValue}
            onChangeStateAthletic={handleStateAthletic}
            autoPublishRoundUp={autoPublishRoundupValue}
            onChangeAutoPublishRoundup={setAutoPublishRoundupValue}
            publishRoundUpOnly={publishRoundUpOnlyValue}
            onChangePublishRoundupOnly={setPublishRoundupOnlyValue}
            apStyleRoundUps={apStyleRoundUpValue}
            onChangeapStyleRoundups={setApStyleRoundUpValue}
            onSubmitSave={onSubmitsave}
            checkedRoundupTagList={checkedRoundupTagList}
            roundupHeadline={roundupHeadlineFormat}
            onChangeRoundupHeadline={setRoundupHeadlineFormat}
          />
        )}
        {activatedTab === "content" && (
          <ContentDeliveryTab
            contentMethodValue={contentMethodValue}
            contentEmailContacts={contentEmailContacts}
            onContentMethodChange={handleContentMethodChange}
            onChangeEmailContacts={handleChangeEmailContacts}
            onCmsChange={handleCmsMethodChange}
            cmsValue={cmsValue}
            deep_link={deepLink}
            publish_status={publishStatus}
            ciatAssociationIds={ciatAssociationIds}
            ciatBY={ciatBy}
            ciatBylineSource={ciatBylineSource}
            ciatSiteCode={ciatSiteCode}
            ciatPaywallType={ciatPaywallType}
            brightSpotFormat={brightSpotFormat}
            brightSpotType={brightSpotType}
            rssFeedUrl={rssFeedUrl}
            onChangeBrightSpotFormat={setBrightSpotFormat}
            onChangeBrightSpotType={setBrightSpotType}
            onChangeCiatAssociationIds={setCiatAssociationIds}
            onChangeCiatBY={setCiatBy}
            onChangeCiatBylineSource={setCiatByLineSource}
            onChangeCiatPaywallType={setCiatPaywallType}
            onChangeCiatSiteCode={setCiatSiteCode}
            onChangeDeepLink={setDeepLink}
            onChangePublishStatus={setPublishStatus}
            onChangeApikey={handleApiKeyChange}
            apiKeyValue={apiKeyValue}
            apiSecretValue={apiSecretValue}
            onChangeApiSecret={handleApiSecretChange}
            authorNameValue={authorNameValue}
            onChangeAuthorName={handleAuthorNameChange}
            checkedSeoMetaList={checkedSeoMetaList}
            additionalTags={additionalTagValue}
            onChangeAdditionalTag={handleAdditionalTagChange}
            urlSlugValue={urlSlugValue}
            testPubColor={testPubButtonColor}
            testPubButtonStatus={testPubButtonStatus}
            onChangeUrlSlug={setUrlSlugValue}
            apiGetUrlValue={apiGetUrlValue}
            onChangeApiGetUrl={handleApiGetUrlChange}
            apiPostUrlValue={apiPostUrlValue}
            onChangeApiPostUrl={handleApiPostUrlChange}
            editMode={editMode}
            onClicktestPublish={testPublish}
            onSubmitSave={onSubmitsave}
          />
        )}
          {activatedTab === "advance" && (
            <AdvanceTab
              timezoneValue={timezoneValue}
              hoursValue={hoursValue}
              minutesValue={minutesValue}
              amPMvalue={amPMValue}
              onTimezoneChange={handleTimezoneChange}
              onHourChange={handleHourChange}
              onMinutesChange={handleMinutesChange}
              onAMPMChange={handleAMPMChange}
              onChangePausePublish={handlePausePublishChange}
              isCheckedPausePublish={isCheckedPausePublish}
              editMode={editMode}
              checkedList={checkedSportList}
              checkedGenderLevelList={checkedGenderLevelList}
              onSubmitSave={onSubmitsave}
            />
          )}        
      </div>
      <Modal
        isOpened={isConfirmModalOpened}
        className="confirmation-modal"
        closeModal={closeConfirmModal}
      >
        <ModalTitle className="fw-bold text-center text-dark mb-2">
          Save Changes?
        </ModalTitle>
        <div className="text-dark text-center mb-5">
          You have unsaved edits to this outlet.
        </div>
        <div
          className="text-center mt-n2 d-flex justify-content-between"
          style={{ width: "70%", margin: "auto" }}
        >
          <button
            style={{ width: 184, margin: "20" }}
            className="btn btn--orange"
            type="submit"
            onSubmit={onSubmitsave}
            onClick={() => {
              closeConfirmModal();
            }}
          >
            Save Changes
          </button>
          <button className="btn btn--outline">Close Without Saving</button>
        </div>
      </Modal>
    </>
  );
};

export default Configuration;

const ModalTitle = styled.div`
  font-size: 32px;
  line-height: 48px;
  color: #072136;
`;