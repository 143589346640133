import { SelectInput, RichTextEditor, Input } from "../../components";
import { toast } from "react-toastify";
import { useState } from "react";
import { STATES, AREATYPES, LANGUAGES } from "../../constants/constants";
import { ReactComponent as Alert } from "./../../icons/Alert.svg";
import { OPTION, CHECKEDOPTION } from "../../core/types";
import { ChangeEvent, ChangeEventHandler, MouseEvent, MouseEventHandler } from "react";
import { getPubCities } from "../../core/api";

import "./ConfigTab.scss";

interface Props {
  editMode: boolean;
  editorValue: string;
  checkedStates: OPTION[];
  checkedcityState: string;
  checkedCities: OPTION[];
  includeCities?: any;
  checkedIncludeCities: OPTION[];
  coverageType?: string;
  isCheckedGenAI?: boolean;
  isCheckedSeoOpt?: boolean;
  isCheckedHeadlineScore?: boolean;
  isCheckedGenderSport?: boolean;
  isCheckedCityName: boolean;
  isSportsDetailed?: boolean;
  isCheckedContentDynamic?: boolean;
  getCitiesButtonState: boolean;
  includeCityState: boolean;
  cityReload: boolean;
  cityOptions?: any;
  cityName?: string;
  language?: string;

  onChangeIncludeCityState: (value: boolean) => void;
  onChangeGenAI: (value: boolean) => void;
  onEditorChange: (value: string) => void;
  onCoverageTypeChange: (value: string) => void;
  onChangeSeoOpt: (value: boolean) => void;
  onChangeHeadlineScore: (value: boolean) => void;
  onChangeGenderSport: (value: boolean) => void;
  onChangeCityName: (value: boolean) => void;
  onChangeLanguage: (value: string) => void;
  onChangeSportsDetailed: (value: boolean) => void;
  onChangeContentDynamic: (value: boolean) => void;
  setgetCitiesButtonState: (value: boolean) => void;
  onCityCheckedStateChange: (value: string) => void;
  onCityCheckedListChange: (value: []) => void;
  handleCityNameChange: ChangeEventHandler<HTMLInputElement>;
  setCityReload: (value: boolean) => void;
  setCityOptions: (value: []) => void;
  onSubmitSave: any
}

const StandardTab = (props: Props) => {

  const onChangeStateForCity = (value: string) => {
    if (value != ""){
      props.setgetCitiesButtonState(false);
      props.onCityCheckedStateChange(value);
    }
  }

  const cityList: any = [];
  let cityValueList: string[] = [];
  const [error, setError] = useState('');

  // setIncludeCityState(props.includeCities ? true : false);

  const getCities: MouseEventHandler<HTMLButtonElement> = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
   props.setCityReload(false);
   props.onCityCheckedListChange([]);
   toast.info("Loading Cities", {
    position: "top-right",
    autoClose: 4500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "dark",
  });
   getPubCities(props.cityName+","+props.checkedcityState)      
   .then((res) => {
     cityValueList = res.city_list;
     res.city_list.forEach((city: any) => {
        cityList.push({label: city, value: city, isChecked: true});
      })
      props.setCityOptions(cityList);
      props.onCityCheckedListChange(cityList);
      props.setCityReload(true);
  })
   .catch((err) => {
     console.error(err);
     toast.error(err.message, {
       position: "top-right",
       autoClose: 4500,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       theme: "dark",
     });
   });
  }

  // useEffect(() => {
  //   setCityReload(false);
  // }, [cityReload]);

  const allFiltersPre = [
    {
      subtitle: "Coverage Area Type",
      value: [],
      options: STATES,
    },
  ];

  return (
    <div className="form-container">
      <form className="w-100 px-4 d-flex flex-column configuration-form ">
        <span className="fw-bold mb-4 section-title">Coverage Area</span>
        <div className="input-container">
            <SelectInput.SelectDropdown
              options={AREATYPES}
              title={"Coverage Area Type"}
              value={props.coverageType}
              required={true}
              onChange={props.onCoverageTypeChange}
              disabled={!props.editMode}
            />
        </div>
        <div className="input-container ">
            { props.coverageType == 'state' ? (
            <SelectInput.MultiSelectDropdown
              value={allFiltersPre[0].value}
              required={true}
              title="Select States"
              checkedList={props.checkedStates}
              options={allFiltersPre[0].options}
              disabled={!props.editMode}
              onChange={() => null}
            />
          
          ) : (
          <div className="input-container">
          {props.editMode && (
            <div className="input-container">
              <SelectInput.SelectDropdown
                options={allFiltersPre[0].options}
                title={"Select State for DMA"}
                value={props.checkedcityState}
                required={true}
                onChange={onChangeStateForCity}
                disabled={!props.editMode}
              />
            </div>
          )}
{/* 
            <div className="input-container"> */}
              <span className="fw-bold mb-2 d-block">Your Primary City</span>
              <div className='d-flex Input__container w-50'>

                <input
                  value={props.cityName ? props.cityName : ""}
                  onChange={props.handleCityNameChange}
                  className="light getcity-input"
                  name="cityname"
                  style={{ fontSize: "22px" , height: "30px", width: "100px" , padding: "25px 25px 25px 25px"}}
                  >
                  </input>
              <button
                className="btn btn-secondary align-self-start mb-5"
                style={{ height: "50px", width: "100px" , padding: "0px 20px", display: "inline-block", fontSize: "15px", marginLeft: "25px"}}
                onClick={getCities}
                type="button"
                disabled={props.getCitiesButtonState}
            >
              Get Cities
            </button>
            </div>

{/* </div> */}
           {props.cityReload && (
            <div className="input-container">
              <SelectInput.MultiSelectDropdown
                required={false}
                title="Select Cities"
                checkedList={props.checkedCities}
                options={props.cityOptions}
                disabled={!props.editMode}
                preCheckedAll={true}
                value={[]}
                onChange={() => null}
                showMenuoption={true}
              />
            </div>
           )}
          {props.includeCityState && (
            <div className="input-container">
              <SelectInput.MultiSelectDropdown
                required={false}
                title="Cities Already Covered"
                checkedList={props.checkedIncludeCities}
                disabled={!props.editMode}
                options={props.includeCities}
                value={[]}
                onChange={() => null}
              />
            </div>
           )}
          </div>
        )
        }
        </div>
        <span className="fw-bold mb-4 section-title">Coverage Details</span>
        <div className="input-container">
          {props.editMode ? (
            <SelectInput.SelectDropdown
              options={LANGUAGES}
              title={"Language"}
              value={props.language}
              required={true}
              onChange={props.onChangeLanguage}
              initlabel={"English"}
            />
          ) : (
            <>
              <span className="fw-bold mb-2 d-block">Language</span>
              <span className="d-block">English</span>
            </>
          )}
        </div>
        <span className="fw-bold mb-4 section-title">
          In-Article Configurations
        </span>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Use experimental generative AI to write articles
          </span>
          <span className="mb-2 d-block">
            We are using generative AI in production to write articles. Articles
            written with generative AI are longer, richer, and over 99%
            accurate. These articles include data such as each team’s historical
            record (when applicable) and other, relevant statistics. Note that
            generative AI is still experimental and can be prone to error.
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-yes"
                  name="writearticles"
                  checked={props.isCheckedGenAI}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeGenAI(e.target.checked)
                  }
                />
                <label htmlFor="dl-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-no"
                  name="writearticles"
                  checked={!props.isCheckedGenAI}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeGenAI(!e.target.checked)
                  }
                />
                <label htmlFor="dl-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Use SEO-optimized lede for each article
          </span>
          <span className="mb-2 d-block">
            For many clients, this SEO-optimized lede has raised pageviews per
            article by at least 10%. This lede format would be the same for each
            article we publish.
            <br />
            <br />
            [City Name] [Team A] beat [City Name] [Team B] [Score] in a/an
            [State] high school [sport] game on [Date].
            <br />
            <br />
            Columbus St. Charles beat Columbus St. Francis DeSales 36 - 3 in an
            Ohio high school football game on September 29.
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-seoarticles-yes"
                  name="seoarticles"
                  checked={props.isCheckedSeoOpt}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeSeoOpt(e.target.checked)
                  }
                />
                <label htmlFor="dl-seoarticles-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-seoarticles-no"
                  name="seoarticles"
                  checked={!props.isCheckedSeoOpt}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeSeoOpt(!e.target.checked)
                  }
                />
                <label htmlFor="dl-seoarticles-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Include score in headline
          </span>
          <span className="mb-2 d-block">
            It is highly recommended that we do not include the score in the
            headline.
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-headlinescore-yes"
                  name="headlinescore"
                  checked={props.isCheckedHeadlineScore}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeHeadlineScore(e.target.checked)
                  }
                />
                <label htmlFor="dl-headlinescore-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-headlinescore-no"
                  name="headlinescore"
                  checked={!props.isCheckedHeadlineScore}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeHeadlineScore(!e.target.checked)
                  }
                />
                <label htmlFor="dl-headlinescore-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Include gender and sport in headline
          </span>
          <span className="mb-2 d-block">
            For example: “Girls Basketball: Team A defeats Team B”
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-gendersport-yes"
                  name="gendersport"
                  checked={props.isCheckedGenderSport}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeGenderSport(e.target.checked)
                  }
                />
                <label htmlFor="dl-gendersport-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-gendersport-no"
                  name="gendersport"
                  checked={!props.isCheckedGenderSport}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeGenderSport(!e.target.checked)
                  }
                />
                <label htmlFor="dl-gendersport-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Include city name with school (when appropriate)
          </span>
          <span className="mb-2 d-block">
            Useful if you have a large coverage area where multiple schools
            could have the same name.
            <br />
            <br />
            For example, “Toledo St. Francis” and “Columbus St. Francis.” City
            names will not be included in the event the city name and the school
            name are the same.
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-cityname-yes"
                  name="cityname"
                  checked={props.isCheckedCityName}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeCityName(e.target.checked)
                  }
                />
                <label htmlFor="dl-cityname-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-cityname-no"
                  name="cityname"
                  checked={!props.isCheckedCityName}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeCityName(!e.target.checked)
                  }
                />
                <label htmlFor="dl-cityname-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">
            Do not write about quarter scores
          </span>
          <span className="mb-2 d-block">
            Do not write about quarter scores. If you select “Yes,” all articles
            will only be a headline + lede + deep linking to previous games.
          </span>
            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-quarterscores-yes"
                  name="quarterscores"
                  checked={props.isSportsDetailed}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeSportsDetailed(e.target.checked)
                  }
                />
                <label htmlFor="dl-quarterscores-yes">Yes</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-quarterscores-no"
                  name="quarterscores"
                  checked={!props.isSportsDetailed}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeSportsDetailed(!e.target.checked)
                  }
                />
                <label htmlFor="dl-quarterscores-no">No</label>
              </div>
            </div>
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">Choose content dynamics</span>
          <span className="mb-2 d-block">
            High Dynamics is content that is a little more interesting. For
            example, “Team A outlastsTeam B in topsy-turvy battle.” Low Dynamics
            is content that is a bit more standard, such as “Team A beats Team
            B.” If you use generative AI, your “Content Dynamics” selection will
            be ignored.
          </span>

            <div className="radio-group ">
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-contentdynamics-yes"
                  name="contentdynamics"
                  checked={props.isCheckedContentDynamic}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeContentDynamic(e.target.checked)
                  }
                />{" "}
                <label htmlFor="dl-contentdynamics-yes">High Dynamics</label>
              </div>
              <div className="mb-2 d-flex  align-items-center radio-input ">
                <input
                  type="radio"
                  id="dl-contentdynamics-no"
                  name="contentdynamics"
                  checked={!props.isCheckedContentDynamic}
                  disabled={!props.editMode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onChangeContentDynamic(!e.target.checked)
                  }
                />{" "}
                <label htmlFor="dl-contentdynamics-no">Low Dynamics</label>
              </div>
            </div>

        </div>
        <div className="edit-container">
          <span className="fw-bold mb-2 d-block">Boilerplate Text</span>
          <span className="mb-4 d-block">
            This content will appear at the bottom of every article. Below is an
            example, and feel free to make it your own.
          </span>
            <RichTextEditor
              text={props.editorValue}
              onChange={props.onEditorChange}
            />
        </div>
        <button
          className="btn btn-primary align-self-start mt-5 "
          style={{ height: "55px", width: "118px" }}
          type="button"
          onClick={props.onSubmitSave}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default StandardTab;
