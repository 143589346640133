import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { PUBLICATION } from "./types";
import { RegisterInput } from "../pages/SignUp/SignUp";
import {
  ACCESS_TOKEN_KEY,
  ID_TOKEN_KEY,
  isTokenInvalid,
  refreshAccessToken,
  REFRESH_TOKEN_KEY,
  removeTokens,
} from "./auth";
import { getEmailFromToken } from "./helpers";
// import { normalizeImpact, normalizePost } from "./normalization";
import { Client } from "./types";
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";


export const refreshTokens = async () => {
  try {
    const res = await refreshAccessToken(
      localStorage.getItem(REFRESH_TOKEN_KEY) || ""
    );
    localStorage.setItem(
      ACCESS_TOKEN_KEY,
      res.AuthenticationResult!.AccessToken!
    );
    localStorage.setItem(ID_TOKEN_KEY, res.AuthenticationResult!.IdToken!);
  } catch (error) {
    removeTokens();
  }
};

axios.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || "";

    if (!accessToken) {
      return config;
    }

    if (isTokenInvalid(accessToken)) {
      await refreshTokens();
    }
    if (axios.defaults.headers != null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;
      axios.defaults.headers.common['id-token'] = localStorage.getItem(ID_TOKEN_KEY);
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/* export const getPreviousArticles = async () => {
  const decodedToken: { email: string } = jwtdecode(
    localStorage.getItem(ID_TOKEN_KEY)!
  );
  const { email } = decodedToken;
  const res = await axios.get<Post[]>(`${API_URL}/publishing-history`, {
    params: {
      user_id: email,
    },
  });
  return res.data.map(normalizePost);
}; */

export const register = async (
  data: Omit<RegisterInput, "confirm_password">
) => {
  const res = await axios.post(`${API_URL}/register`, data);
  return res.data;
};

export const getUser = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`, 'id-token': localStorage.getItem(ID_TOKEN_KEY) };
  const res = await axios.get(`${API_URL}/get-user-data`, { headers });
  return res.data;
};

export const confirmAccount = async (
  data: any
) => {
    const res = await axios.post(`${API_URL}/confirm-account`, data);
    return res.data;
};

export const getCreatorCategories = async () => {
  const res = await axios.get<string[]>(`${API_URL}/creator/categories`);
  return res.data;
};

export const getAllClients = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`};
  const res = await axios.get<Client[]>(`${API_URL}/api/v1/clients`, { headers });
  return res;
};
export const addPublicationClientNoFound = async (data : any) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };

  const res : AxiosResponse  = await axios.post(`${API_URL}/api/v1/publications`, data, {headers});
  return res.data;
};

export const updatePublication = async (data: any) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };
  const res = await axios.patch(`${API_URL}/api/v1/publications/${data.name}`, data, {headers});
  return res.data

}

export const DeletePublication = async (pub_name: any) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };
  const res = await axios.delete(`${API_URL}/api/v1/publications/${pub_name}`,{headers});
  
  window.location.href = "/"

}


export const getPublication = async (pub_name: any) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };
  const res = axios.get(`${API_URL}/api/v1/publications/${pub_name}`,{headers});

  return res;
}


export const addNewClient = async (data : any) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };

  const res : AxiosResponse  = await axios.post(`${API_URL}/api/v1/clients`, data, {headers});
  return res.data;
};

export const getPubCities = async (city_name: string) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };

  const res = await axios.get(`${API_URL}/api/v1/publications/cities/${city_name}`, {headers})
  console.log(res.data)
  return res.data;
}

export const testPublishConnection = async (data: any) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };
  const res : AxiosResponse  = await axios.post(`${API_URL}/api/v1/publications/testconnection`, data, {headers});
  return res.data;
}
/* export const getCreatorConfig = async (category: string) => {
  const res = await axios.get<CreatorConfig>(
    `${API_URL}/creator/configs/${category}`
  );
  return res.data;
}; */

export const getPublishedArticles = async (pub_name: string|null) => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    'Content-Type': 'application/json',
  };

  const res = await axios.get(`${API_URL}/publishing-history/articles?publication=${pub_name}`, {headers})
  console.log(res.data)
  return res.data;
}

export const downloadPublishedFiles = async (
  data: any
) => {
    const res = await axios.post(`${API_URL}/publishing-history/articles/download`, data, {
      responseType: 'arraybuffer'
    });
    console.log(res)
    return res
};

export const postFile = async (file: File, content_name: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("content_name", content_name);
  const res = await axios.post<
    FormData,
    AxiosResponse<{
      items: { [key: string]: string | number | null }[];
      data_map: { [key: string]: string };
    }>
  >(`${API_URL}/creator/upload-file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

/* export const getImpact = async () => {
  const res = await axios.get(`${API_URL}/impact`, {
    params: {
      user_id: getEmailFromToken(),
      company_name: "Teammate AI",
    },
  });
  return normalizeImpact(res.data);
};
 */
/* export const postPreview = async (data: PostPreviewInput) => {
  const res = await axios.post<
    PostPreviewInput,
    AxiosResponse<{
      preview_data: PreviewData[];
      mapped_data: ParsedDataField[];
    }>
  >(`${API_URL}/creator/generate-preview`, data);
  return res.data;
};

export const postGenerateContent = async (data: GenerateContentInput) => {
  const res = await axios.post<
    GenerateContentInput,
    AxiosResponse<ContentItem[]>
  >(`${API_URL}/creator/generate-content`, data);
  return res.data;
};

export const postSavedFinishedContent = async (data: FinishedAsset) => {
  const res = await axios.post(`${API_URL}/finished_asset`, data);
  return res.data;
};
 */