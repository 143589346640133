
import { useState, useEffect } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrows-down-solid.svg';
import { ReactComponent as ArrowUpIcon } from '../../icons/arrows-up.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import './MultiSelectDropdown.scss'
import {CHECKEDOPTION, OPTION} from '../../core/types'
import { Checkbox } from "../../components";



interface Props {
    options: CHECKEDOPTION[];
    checkedList?: CHECKEDOPTION[];
    value?: string[];
    title?: string;
    onChange: (selected: OPTION) => void;
    required?: boolean,
    searchBar?: boolean,
    checkedItemsView?: boolean,
    className?: string,
    preCheckedAll?: boolean,
    disabled?: boolean,
    showMenuoption?: boolean

}

export function MultiSelectDropdown({ value = [], checkedList = [], preCheckedAll = false, options, title, onChange, required, disabled=false, className = "", checkedItemsView = true, showMenuoption = false, searchBar = true, ...rest }: Props) {
    const [showMenu, setShowMenu] = useState(showMenuoption);
    const [isCheckedAll, setCheckedAll] = useState(preCheckedAll);
    const [search, setSearch] = useState('');
    const [checkboxes, setCheckboxes] = useState<OPTION[]>(options);
    const [checkedItems, setCheckedItems] = useState<OPTION[]>(checkedList);
    const [checkedItemsText, setCheckedItemsText] = useState<string>('');

    useEffect(() => {

        if (checkedItems.length > 4) {
            const initial_checkedItemText = checkedItems.slice(0, 4).map((item) => item.label).join(', ');
            setCheckedItemsText(initial_checkedItemText + " (+" + (checkedItems.length - 4) + ")")
        } else {
            if (checkedItems.length > 0)
                setCheckedItemsText(checkedItems.map((item) => item.label).join(', '));
            else
                setCheckedItemsText("");
        }

        setCheckboxes(checkboxes.map((item) => {
            return checkedItems.map(checkedItem => checkedItem.value).includes(item.value) ? { ...item, isChecked: true } : item
        }))
    }, []);

    const onAllSelectionChange = (isChecked: boolean) => {
        setCheckedAll(isChecked);
        const filteredData = checkboxes.filter(o => o.label.toLowerCase().includes(search));
        filteredData.map(checkbox => {
            checkbox.isChecked = !isCheckedAll;
            toggleCheckedItem(checkbox);
        });
    }

    const onSearchInputChange = ({target:{value}}: {target:{value: string}}) => {
        setSearch(value || '');
        handleAllCheck(value, checkboxes);
    }

    const handleAllCheck = (value: string, checkboxes: any[]) => {
        const filteredData = checkboxes.filter(o => o.label.toLowerCase().includes(value));
        const isAllChecked: boolean = filteredData.every(checkbox => checkbox.isChecked);
        setCheckedAll(isAllChecked);
    }

    const toggleCheckedItem = (value: OPTION) => {
        let newCheckedItems = checkedItems;
        
        if (value.isChecked) {
            const itemIndex: number = newCheckedItems.findIndex(item => item.value === value.value);

            if (itemIndex === -1) {
                newCheckedItems.push(value);
            }
        } else {
            const itemIndex: number = newCheckedItems.findIndex(item => item.value === value.value);
            newCheckedItems.splice(itemIndex, 1);
        }

        if (newCheckedItems.length > 4) {
            const initial_checkedItemText = newCheckedItems.slice(0, 4).map((item) => item.label).join(', ');
            setCheckedItemsText(initial_checkedItemText + " (+" + (newCheckedItems.length - 4) + ")")
        } else {
            if (newCheckedItems.length > 0)
                setCheckedItemsText(newCheckedItems.map((item) => item.label).join(', '));
            else
                setCheckedItemsText("");
        }

        setCheckedItems(newCheckedItems);
    }

    const onSelectionChange = (option: OPTION, isChecked: boolean) => {

        const newCheckboxes = checkboxes.map((item) => {
            return item.value === option.value ? { ...item, isChecked: !item.isChecked } : item
        });

        setCheckboxes(newCheckboxes);
        handleAllCheck(search, newCheckboxes);

        const newValue = { ...option, isChecked };
        
        toggleCheckedItem(newValue);

        onChange({ ...option, isChecked: isChecked });
    }

    return (
        <div className={'MultiSelectDropdown  ' + (showMenu ? 'active' : '') + className}>
            <p className="filter-title">{title}</p>
            <div className="" style={{ display: "flex" }} >
                <div className="MultiSelectDropdown__input" onClick={() => setShowMenu(!showMenu)}>
                    <p className="filter-placeholder">{checkedItemsText == '' ? title : checkedItemsText}</p>
                    {!showMenu ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </div>
                <div
                    style={{
                        width: "10px",
                        paddingLeft: "9px",
                        color: '#ee465a',
                        opacity: required ? 1 : 0,
                    }}
                >
                    *
                </div>
            </div>
            <div className='collapse-menu'>
                {checkedItemsText != "" &&
                    <div className=" d-flex selectedItemsContainer">
                        {checkedItems.map((item, index) => (
                            <div key={index} className="selectedItem">{item.label}</div>
                        ))}
                    </div>
                }
                {/* <a className='clearSelect' >Clear All Selected</a> */}
                <div className="dropdown-search">
                    <input type="text" placeholder="Search" onChange={onSearchInputChange} />
                    <SearchIcon />
                </div>
                <ul>
                        <li>
                            <Checkbox checked={isCheckedAll} label={'Select All'} disabled={disabled} onChange={(isChecked) => onAllSelectionChange(isChecked)} labelColor="#415473" />
                        </li>
                        {checkboxes.filter(o => search ? o.label.toLowerCase().includes(search.toLowerCase()) : true).map((option, index) =>
                        (<li key={option.value + index}>
                            <Checkbox checked={option.isChecked} label={option.label} disabled={disabled} onChange={(isChecked) => onSelectionChange(option, isChecked)} labelColor="#415473" />

                        </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}
