import { ChangeEventHandler, MouseEventHandler } from "react";
import { SelectInput, Timer, Checkbox, Table } from "../../components";
import { SPORTS, GENDER_LEVEL, TIMEZONE} from "../../constants/constants";
import { CHECKEDOPTION } from "../../core/types";

import "./ConfigTab.scss";

interface Props {
  onChangePausePublish: (value: boolean) => void;
  onTimezoneChange: (value: string) => void;
  onMinutesChange: ChangeEventHandler<HTMLInputElement>;
  onHourChange: ChangeEventHandler<HTMLInputElement>;
  onAMPMChange: MouseEventHandler<HTMLInputElement>;
  timezoneValue?: string;
  hoursValue?: string;
  minutesValue?: string;
  amPMvalue?: string;
  isCheckedPausePublish: boolean;
  editMode: boolean;
  checkedList: CHECKEDOPTION[];
  checkedGenderLevelList: CHECKEDOPTION[];
  onSubmitSave: any
}

const AdvanceTab = (props: Props) => {
  const allFiltersPreSports = [
    {
      subtitle: "Sports",
      value: [],
      options: SPORTS,
    },
  ];

  const allFiltersPreGenderAndLevel = [
    {
      subtitle: "Sports",
      value: [],
      options: GENDER_LEVEL,
    },
  ];

  return (
    <div className="form-container ">
      <form className="w-100 px-4 d-flex flex-column configuration-form ">
        <span className="fw-bold mb-4 section-title">Advance Settings</span>
        <div className="input-container">
            <SelectInput.MultiSelectDropdown
              value={allFiltersPreSports[0].value}
              required={true}
              title="Sports"
              checkedList={props.checkedList}
              options={allFiltersPreSports[0].options}
              onChange={() => null}
              // disabled={!props.editMode}
              preCheckedAll={true}
            />
        </div>
        <div className="input-container">
            <SelectInput.MultiSelectDropdown
              value={allFiltersPreGenderAndLevel[0].value}
              options={allFiltersPreGenderAndLevel[0].options}
              title={"Gender and Level"}
              checkedList={props.checkedGenderLevelList}
              // disabled={!props.editMode}
              required={false}
              preCheckedAll={true}
              onChange={() => null}
            />
        </div>
        <span className="fw-bold mb-4 section-title">Publishing Times</span>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">Time Zone</span>
            <SelectInput.SelectDropdown
              value={props.timezoneValue}
              options={TIMEZONE}
              title={""}
              required={true}
              onChange={props.onTimezoneChange}
              // disabled={!props.editMode}
            />
        </div>
        <div className="input-container">
          <span className="fw-bold mb-2 d-block">Publishing Time</span>
          <Timer
            am={props.amPMvalue == 'AM' ? true: false }
            className="mx-2"
            required={true}
            hours={props.hoursValue ? props.hoursValue : "10"}
            minutes={props.minutesValue ? props.minutesValue : "10"}
            editable={props.editMode}
            onHourChange={props.onHourChange}
            onMinutesChange={props.onMinutesChange}
            onAMPMChange={props.onAMPMChange}
          ></Timer>
          <Checkbox
            checked={props.isCheckedPausePublish}
            className="mt-2 text--primary"
            label={"Pause Publishing"}
            onChange={props.onChangePausePublish}
          ></Checkbox>
        </div>
        {/* <span className="fw-bold text--dark  mb-3 section-title">
          Custom School Names
        </span>
        <div className="input-container">
          <Table />
        </div> */}
        <button
          className="btn btn-primary align-self-start"
          style={{ height: "55px", width: "118px" }}
          type="button"
          onClick={props.onSubmitSave}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default AdvanceTab;
