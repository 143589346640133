import { FormikErrors, useFormik } from "formik";
import { useState} from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Input } from "../../components";
import Loader from "../../components/Loader/Loader";
import LoginContainer from "../../containers/LoginContainer";
import { RoutesEnum } from "../../core/enums";
import { isEmail } from "../../core/helpers";
import { setUser } from "../../core/store/user/userSlice";
import { useAppDispatch } from "../../hooks/useReduxToolkit";
import { login, setTokensToLocalStorage } from "../../core/auth";
import "./Login.scss";
import { getUser } from "../../core/api";

interface FormikValues {
  email: string;
  password: string;
}

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {};
      if (!values.email) {
        errors.email = "Required";
      }

      if (values.email && !isEmail(values.email)) {
        errors.email = "Email not valid";
      }

      if (!values.password) {
        errors.password = "Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      // dispatch(setUser(true));
      setIsLoading(true);

      login(values)
        .then((res) => {
          setTokensToLocalStorage(
            res.AuthenticationResult!.AccessToken!,
            res.AuthenticationResult!.RefreshToken!,
            res.AuthenticationResult!.IdToken!
          );

          return getUser();
        })
        .then((res) => {
          console.log(res)
          dispatch(setUser(res));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          toast.error(err.message, {
            position: "top-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "dark",
          });
        });
    },
  });

  return (
    <LoginContainer>
      {isLoading && <Loader />}
      <div className="Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100">
        <div className="Login__title fw-bold mb-2">Log In</div>
        <div className="mb-4">
          Welcome! If you do not have an account, please contact an admin.
        </div>
        <form className="w-100 Login__form " onSubmit={formik.handleSubmit}>
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            required={true}
            label="Email"
            className="mb-3"
            error={formik.touched.email && formik.errors.email}
          />
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onChange={formik.handleChange}
            name="password"
            label="Password"
            required={true}
            className="mb-2"
            type="password"
            error={formik.touched.password && formik.errors.password}
          />
          <div className="text-end fw-bold mb-4">
            <Link to={RoutesEnum.ForgotPassword}>Forgot Password?</Link>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <button className="btn w-100 Login__btn mb-3" type="submit">
              Log In
            </button>
            {/* <div>
              New User?{" "}
              <Link to={RoutesEnum.SignUp} className="fw-bold">
                Sign Up!
              </Link>
            </div> */}
          </div>
        </form>
      </div>
    </LoginContainer>
  );
}
