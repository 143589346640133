import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  GlobalSignOutCommand
} from '@aws-sdk/client-cognito-identity-provider';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const IDENTITYPOOL_ID = process.env.REACT_APP_USERPOOL_ID;
export const UserPoolId = process.env.REACT_APP_USERPOOL_ID;
export const Region = process.env.REACT_APP_REGION;
export const ClientId = process.env.REACT_APP_USERPOOL_CLIENT_ID;
export const REFRESH_TOKEN_KEY = `refresh_token_${process.env.NODE_ENV}`;
export const ACCESS_TOKEN_KEY = `access_token_${process.env.NODE_ENV}`;
export const ID_TOKEN_KEY = `id_token_${process.env.NODE_ENV}`;
export const EMAIL_KEY = `email_${process.env.NODE_ENV}`
export const PASSWORD_KEY = `password_${process.env.NODE_ENV}`

export interface LoginModel {
  email: string;
  password: string;
}

export const client = new CognitoIdentityProviderClient({
  region: 'us-east-2',
});

export const login = async ({ email, password }: LoginModel) =>
  await client.send(
    new InitiateAuthCommand({
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
      ClientId,
    })
  );

export const refreshAccessToken = async (refreshToken: string) =>
  await client.send(
    new InitiateAuthCommand({
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
      ClientId,
    })
  );


export const logOutUser = async () =>{
  try{
    await client.send(
      new GlobalSignOutCommand({
        AccessToken: localStorage?.ACCESS_TOKEN_KEY
      })
    )
  }
  catch{
    console.log("error occured")
  }
    removeTokens();
}


export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
};

export function isTokenInvalid(tokenStr: string) {
  try {
    const token: JwtPayload = jwtDecode(tokenStr);
    return token.exp && Date.now() >= token.exp * 1000;
  } catch (error) {
    return true;
  }
}

export function setTokensToLocalStorage(
  accessToken: string,
  refreshToken: string,
  idToken: string
) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(ID_TOKEN_KEY, idToken);
};

export function setEmailPasswordToLocalStorage(
  email: string,
  password: string
){
  localStorage.setItem(EMAIL_KEY, email);
  localStorage.setItem(PASSWORD_KEY, password);
};