import React, { useState } from 'react'
import './PublicationSetting.scss'
import imgg from './../../imgs/Rectangle 2.png'

const PublicationSettings = () => {
  
    const [data,setData] = useState({publicationName:"RIchland Source",publicationUser:"not assigned"})

  return (
    <div className="p-setting-container d-flex flex-column">
    <div className="header  d-flex mb-5 flex-column ">
      <div className="fw-bold text--orange"   >Publishing History</div>
      <div className='fw-bold text--dark page-heading' >Publisher Name</div>

      </div>


      <div className="ps-container">
        <div className="title">
            Publication Settings
        </div>
        <div className="setting-header">
            <div>Standard Settings</div>
        </div>

        <div className="general-info-container ">
            <div className="title my-2 ">General Info</div>
            
            <div className="item-container">
                <div className=" fw-bold item-label">Publication Name</div>
                <div className="item-value  ">{data.publicationName}</div>

            </div>            
            <div className="item-container">
                <div className=" fw-bold item-label">Publication Image</div>
                <div className="item-image  "><img src={imgg} alt="" /></div>

            </div>
            <div className="item-container">
                <div className=" fw-bold item-label">Publication Users</div>
                <div className="item-value  ">{data.publicationUser}</div>

            </div>
        </div>
    

    </div>
    </div>
  )
}

export default PublicationSettings