import { ChangeEventHandler, MouseEventHandler } from "react";
import "./Timer.scss";

type props = {
  className?: string;
  hours: string;
  minutes: string;
  am: boolean;
  editable: boolean;
  onHourChange: ChangeEventHandler<HTMLInputElement>;
  onMinutesChange: ChangeEventHandler<HTMLInputElement>;
  onAMPMChange: MouseEventHandler<HTMLInputElement>;
  required: boolean;
};
export const Timer = ({ className, hours, minutes, am, editable, onHourChange, onMinutesChange, onAMPMChange, required}: props) => {
  const preventNonNumericInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isNumeric = /^\d*$/;
    const isBackspaceOrDelete = e.key === "Backspace" || e.key === "Delete";
    const isArrowKey = e.key === "ArrowLeft" || e.key === "ArrowRight";
    if (!isNumeric.test(e.key) && !isBackspaceOrDelete && !isArrowKey) {
      e.preventDefault();
    }
  };

  return (
    <div className={`clock-container ${className}`} style={{maxWidth : editable ? '160px' : '217px'}}>
      {!editable && <div className="hours">{hours}</div>}
      {editable && (
        <input
          type="text"
          className="input-hours"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="HH"
          required={required}
          onKeyDown={preventNonNumericInput}
          value={hours}
          onChange={onHourChange}
        />
      )}
      <div className="seperator">
        <div className="dot "></div>
        <div className="space"></div>
        <div className="dot"></div>
      </div>
      {!editable && <div className="minutes">{minutes}</div>}
      {editable && (
        <input
          type="text"
          value={minutes}
          className="input-minutes"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="MM"
          required={required}
          onChange={onMinutesChange}
          onKeyDown={preventNonNumericInput}
        />
      )}
      {!editable && (
        <div className="am-pm">
          <span className={am ? "am" : "pm"}>AM</span>
          <span className={!am ? "am" : "pm"}>PM</span>
        </div>
      )}
      {
        editable && (
          <div className="am-pm">
            <span className={!am ? "am" : "pm"} onClick={onAMPMChange}>AM</span>
            <span className={am ? "am" : "pm"} onClick={onAMPMChange}>PM</span>
        </div>
        )
      }
    </div>
  );
};
