import React from 'react'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`

const Spin = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #415473;
  border-top: 5px solid #f37845;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
  margin: 0 auto;
`

export default function Spinner (): React.ReactElement {
    return (
        <Spin />
    )
  }