import { Link, NavLink, useLocation } from 'react-router-dom';
import { RoutesEnum } from '../../core/enums';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import './Sidebar.scss';

const ADMIN = [
  {
    label: 'Overview',
    href: '/',
    
  },
  // {
  //   label: 'Publication History',
  //   href: '/'
  // },
  // {
  //   label: 'Impact',
  //   href: '/'
  // }
]

const PUBLICATIONS = [
  {
    label: 'Dashboard',
    href: '/'
  },
  {
    label: 'High School Sports',
    href: '/configuration',
  },
  // {
  //   label:'Professional Sports',
  //   href:'/configuration'
  // },
  // {
  //   label: 'Author Profile',
  //   href: '/configuration'
  // },
  {
    label:'Publishing History',
    href:"/history"
  },
  // {
  //   label:'Impact',
  //   href:"/history"
  // },

  // {
  //   label:"Publication Settings",
  //   href:'/publicationSetting',
  // },
]


export default function Sidebar() {
  const isHomePage = window.location.pathname === '/';
  const location = useLocation();
  const pubName = new URLSearchParams(location.search).get('publication');

  return (
    
    <div className='Sidebar'>
      <div className='logo-wrap'>
        <Link to='/'  >
          <Logo />
        </Link>
      </div>

      <div className="categories-container d-flex flex-column align-items-stretch ">
        <div className="category">
          <p className="category-heading">Admin</p>
          <ul className="links-container">
            {
              isHomePage ? (ADMIN.map((i, index) => {
              return (<li key={index} >
                <NavLink exact={true} to={i.href} className='text-decoration-none links'>
                  {i.label}
                </NavLink>
              </li>)
            })) : (PUBLICATIONS.map((i, index) => {
              return (<li key={index} >
                <NavLink exact={true} to={i.href + "?publication=" + pubName} className='text-decoration-none links'>
                  {i.label}
                </NavLink>
              </li>)
            }))
          }
          </ul>
        </div>
        {/* <div className="category">
          <p className="category-heading">Publication</p>
          <ul className="links-container">
            {PUBLICATIONS.map((i, index) => {
              return (<li key={index} >
                <NavLink exact={true} to={i.href} className='text-decoration-none links'>
                  {i.label}
                </NavLink>
              </li>)
            })}

          </ul>
        </div> */}
      </div>

      {/*    <div className='row row-cols-auto'>
          {LINKS.map((l) => (
            <div className='col flex-shrink-0' key={l.label}>
              <NavLink to={l.href} className='fw-bold text-decoration-none'>
                {l.label}
              </NavLink>
            </div>
          ))}
        </div> */}
    </div>


  );
}
