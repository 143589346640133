import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrows-down-solid.svg';
import { ReactComponent as ArrowUpIcon } from '../../icons/arrows-up.svg';
import './SelectDropdown.scss';

interface Props {
    options: {
        label: string;
        value: string;
    }[];
    initlabel?: string,
    value?: string;
    required?: boolean,
    title?: string;
    disabled?: boolean;
    onChange: (selected: string) => void;
}

export function SelectDropdown({ value, required, onChange, initlabel, title, disabled=false, options, ...rest }: Props) {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState(initlabel);

    return (
        <div>
            <span className="filter-title">{title}</span>
            <div className={'SelectDropdown mt-2 ' + (showMenu ? 'active' : '') }>

                <div className="" style={{ display: "flex" }} >
                    <div className='SelectDropdown__input' onClick={() => setShowMenu(!showMenu)}>
                        <span style={(selectedLabel !== '' ? { opacity: 1 } : {})}>{options.find(o => o.value === value)?.label}</span>
                        {!showMenu ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: -20,
                            color: '#ee465a',
                            opacity: required ? 1 : 0,
                        }}
                    >
                        *
                    </div>
                </div>
                <div className='collapse-menu'>
                    <ul>
                        { !disabled &&
                            (
                            options.map((option) =>
                                <li key={option.value}  className={option.value === value ? 'active' : ''} onClick={() => { setShowMenu(false); setSelectedLabel(option.label); onChange(option.value) }}>{option.label}</li>
                            )
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>

    );
}
