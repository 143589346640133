import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate'
import { ContentIcon } from "../../icons/ContentIcon";
import Loader  from "../../components/Loader/Loader"
import { getPublishedArticles, downloadPublishedFiles } from "../../core/api";
import './PublishingHistory1.scss'


const PublishingHistory = () => {
    const [loading, setLoading] = useState(false)
    const [articles, setArticles] = useState([]);
    const limitValue = "10"
    const location = useLocation()
    const [pageNo, setPageNo ] = useState(0)
    const [start, setStart] = useState(-1)
    const [end, setEnd] = useState(-1)
    const currentPub = new URLSearchParams(location.search).get('publication');

    const downloadFile = (data:any, fileName: string) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const downloadFiles = (item: any): void => {

        downloadPublishedFiles(item).then((res) => {
        console.log('File download successful:', res);
        let fileName = 'LedeAI-HighSchool-Sports' + item.date + item.time + '.Zip'; // Default filename if not provided by the server
        downloadFile(res.data, fileName);
        })
        .catch((err) => {
            console.error(err);
            toast.error("Error in Downloading the Files", {
              position: "top-right",
              autoClose: 4500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
            });
          });

      }
    
    useEffect( () => {
        setStart(pageNo * parseInt(limitValue))
    }, [pageNo]);

    useEffect( () => {
        let temp_end = start + parseInt(limitValue)
        setEnd( temp_end > articles.length ? articles.length : temp_end )
    }, [start]);

    useEffect(() => {
        if ( articles.length === 0 ){
            setLoading(true)
            getPublishedArticles(currentPub)      
            .then((res) => {
                console.log(res);
                setArticles(res);
                setLoading(false);
                setPageNo(1);
           })
            .catch((err) => {
              console.error(err);
              setLoading(false);
              toast.error(err.message, {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                theme: "dark",
              });
            });
            }
        ;
    }, []);

  return (
      <>
        {loading && <Loader></Loader>}
        {!loading && articles?.length > 0 &&
            <div className="d-outlet-publishing">
                <div className="overlap-wrapper">
                    <div className="text-wrapper-13">{currentPub}</div>
                    <div className="text-wrapper-14">Publishing History</div>
                        <div className="date-items">

                        <>
                            {articles.slice(start,end).map((item:any, index) => {
                                return (
                                <div className="date-item" key={item.date + item.time}>
                                    <div className="frame-7">
                                        <div className="frame-8">
                                            <p className="date-jul">
                                            <span className="text-wrapper-15">Date: </span>
                                            <span className="text-wrapper-16">{item.display_date}</span>
                                            </p>
                                            <div className="frame-9" />
                                        </div>
                                        <div className="frame-10">
                                            <div className="text-wrapper-17">High School Sports</div>
                                            <p className="publishing-time">
                                                <span className="text-wrapper-15">Publishing Time: </span>
                                                <span className="text-wrapper-18">{item.display_time}</span>
                                            </p>
                                            <div className="button-wrapper">
                                            <button className="download-button" key={item.id} onClick={() => { downloadFiles(item) }}>Download Articles &amp; Data</button>
                                            </div>
                                        </div>
                                    </div>
                                    <ContentIcon className="content-icon" />
                                </div>
                                )
                            })
                        }
                        </>
                        {
                        !loading &&
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            forcePage={pageNo - 1}
                            onPageChange={(e) => { setPageNo(e.selected + 1) }}
                            className="react-pagination p-0"
                            pageCount={Math.ceil(articles.length / parseInt(limitValue)) - 1}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={Math.ceil(articles.length / parseInt(limitValue)) > 5 ? 5 : Math.floor(articles.length / parseInt(limitValue))}
                            renderOnZeroPageCount={null}
                            nextClassName='next'
                            previousClassName='next'
                            disabledClassName='disable'
                        />
                        }

                        </div>
                </div>
            </div>

    }

    {!loading && articles?.length === 0 && (
            <>
              <div className='dashboard_no_result'>
                No Results
              </div>
            </>
          )
    }
</>
  )
}
    
    export default PublishingHistory